//@flow

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import moment from 'moment';

let message_shown = false;
export const getAPIURL = () => {
  const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  if (dev && !message_shown) {
    console.log("USING DEV DB");
    message_shown = true;
  }
  // return "https://api.indemand.tv";
  return dev ? "http://localhost:8080" : "https://api.indemand.tv";
};

export const getAuthURL = () => {
  const dev = !process.env.NODE_ENV || process.env.NODE_ENV === "development";
  if (dev && !message_shown) {
    console.log("USING DEV DB");
    message_shown = true;
  }
  return "https://auth.indemand.tv/";
  // return dev ? "http://localhost:80/" : "https://auth.indemand.tv/";
};

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// export function getChannel(){
//   return window.location.pathname.replace(/^\/+/g, '').split("/")[0];
// }
export function tokenExists(): boolean {
  const t = localStorage.getItem("@token");
  if (t) return true;
  return false;
}
export function getToken(): ?string {
  const t = localStorage.getItem("@token");
  if (t) return t;
  return null;
}

export function getUrlParameter(name: string) {
  name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getChannel(): string {
  // console.log("domain", window.location.hostname);
  // run against regex
  // const matches = window.location.href.match(/^https?\:\/\/([^\/?#]+)(?:[\/?#]|$)/i);
  // // extract hostname (will be null if no match is found)
  // if( matches) {

  //   const path = matches[1];
  // console.log(path)
  // return path;
  // };
  let chan = window.location.hostname;
  // console.log(chan)
  if (chan === "localhost" || chan === "192.168.0.13") return "indemand.tv";

  // if (window.location.hostname === "indemand.tv") {
  //   chan = window.location.pathname.replace(/^\/+/g, "").split("/")[0];
  // }
  // if (chan === "") chan = "idtv-beta.tv";
  // if (chan === "") chan = "reminisce";

  // if (chan === "reminisce") window.location.href = "https://reminiscefestival.tv"; //chan = "reminiscefestival.tv"
  // console.log(chan)
  return chan;
  // switch (window.location.hostname) {
  //   case "localhost":
  //     return "idtv-beta.tv";
  //   default:
  //     return "0";
  // }
}

export function setPageMeta(channel: Object,title:string) {
  // console.log("set meta", channel);

  // document.getElementsByTagName('meta')["keywords"].content = "My new page keywords!!";
  document.getElementsByTagName("meta")["description"].content = channel.longDescription;
  document.title = title + " | " +channel.title;
  const ogtitle = document.querySelector('meta[property="og:title"]');
  ogtitle && ogtitle.setAttribute("content", title + " | " +channel.title);
  const ogurl = document.querySelector('meta[property="og:url"]');
  ogurl && ogurl.setAttribute("content", channel.domain);
  const ogdesc = document.querySelector('meta[property="og:description"]');
  ogdesc && ogdesc.setAttribute("content", channel.longDescription);
}


let clientIDCached = "";
export function getClientID(): string {
  if (clientIDCached !== "") return clientIDCached;

  let clientID = localStorage.getItem("@clientid");
  if (!clientID) {
    clientID = uuidv4();
    localStorage.setItem("@clientid", clientID);
  }
  clientIDCached = clientID;
  return clientID;
}


// export class Helpers {
  export function getPrettyDate(date: mixed): string {
    const m = new moment.utc(date);
    if (!m.isValid()) return "";
    return m.format("DD/MM/YY");
  }
  export function getPrettyDateTime(date: mixed): string {
    // console.log(date);;
    const m = new moment.utc(date);
    if (!m.isValid()) return "";
    return m.format("Do MMM YYYY @ HH:mm");
  
    // return m.format("DD/MM/YY @ HH:mm")
  }
  
  export function getPrettyDateDobStyle(date: mixed): string {
    const m = new moment.utc(date);
    if (!m.isValid()) return "";
  
    return m.format("DD/MM/YY");
  }
  
  export function getPrettyDuration(duration: Object): string {
    let mins = duration.minutes();
    let hrs = duration.hours();
  
    let h = hrs > 0 ? hrs + "h " : "";
    let m = mins > 0 ? mins + "m" : "";
  
    return h + m;
  }
  export function stringIsDateTime(s: string): boolean {
    const iso = RegExp(
      /^(\d{4})(?:-?W(\d+)(?:-?(\d+)D?)?|(?:-(\d+))?-(\d+))(?:[T ](\d+):(\d+)(?::(\d+)(?:\.(\d+))?)?)?(?:Z(-?\d*))?$/
    );
    return iso.test(s);
  }
  
  export function getCurrencySymbol(currencyCode: string): string {
    if (currencyCode === "GBP") return "£";
    if (currencyCode === "USD") return "$";
    if (currencyCode === "EUR") return "€";
    return "#";
  }
  
  export function copyToClipboard(text: string):* {
    if (window.clipboardData && window.clipboardData.setData) {
      // IE specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      window.document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in MS Edge.
      window.document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return false;
      } finally {
        window.document.body.removeChild(textarea);
      }
    }
  }
  export function findGetParameter(parameterName: string) :?string{
    let result = null,
      tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach(function(item) {
        tmp = item.split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  }
  
  let replacerURL = "";
  
  function replacer(match, p1) {
    // console.log(match);
    // console.log(p1);
    const url = `<a href=${replacerURL}>${p1}</a>`;
    return url;
  }
  export function replaceLinkTags(text: string, url: string): string {
    replacerURL = url;
    if (typeof text === "string")
      return text.replace(/##link:(.*?)##/gi, replacer);
    return text;
  }
  
  export function makeFileName(s: string): string {
    return s.replace(/ /g, "-").toLowerCase();
  }
  
  export function getRaw(html: string) :*{
    return {
      __html: html
    };
  }
  
  export function urlIsImage(url: string): boolean {
    if (url.endsWith(".jpg")) return true;
    if (url.endsWith(".jpeg")) return true;
    if (url.endsWith(".png")) return true;
    if (url.endsWith(".gif")) return true;
    return false;
  }
  export function urlIsVideo(url: string): boolean {
    if (url.endsWith(".mp4")) return true;
    if (url.endsWith(".mov")) return true;
    return false;
  }
  export function formatPostcode(postcode: string): string {
    if (!postcode) return "";
    const p = postcode
      .replace(/ /g, "")
      .toUpperCase()
      .slice(0, 7);
    const p1 = p.substr(0, p.length - 3);
    const p2 = p.substr(-3);
    return p1 + " " + p2;
  }
  export function formatPhonenumber(phonenumber: string): string {
    if (!phonenumber) return "";
    let p1 = "";
    let p2 = "";
    let p3 = "";
    //strip whitespace
    const p = phonenumber.replace(/ /g, "");
    if (phonenumber.substr(0, 4) === "+44(") {
      p1 = p.substr(0, 10);
      p2 = p.substr(10, 3);
      p3 = p.substr(13);
    } else if (phonenumber.substr(0, 3) === "+44") {
      p1 = "+44(0)" + p.substr(3, 4);
      p2 = p.substr(7, 3);
      p3 = p.substr(10);
    } else {
      p1 = p.substr(0, 5);
      p2 = p.substr(5, 3);
      p3 = p.substr(8);
    }
    return p1 + " " + p2 + " " + p3;
  }
  
  export function getRandomColour() :string{
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  export function getColourWithIndex(i: number, offset?: number = 0): string {
    const colours = [
      "#e6194B",
      "#3cb44b",
      "#ffe119",
      "#4363d8",
      "#f58231",
      "#911eb4",
      "#42d4f4",
      "#f032e6",
      "#bfef45",
      "#fabebe",
      "#469990",
      "#9A6324",
      "#800000",
      "#aaffc3",
      "#808000",
      "#000075"
    ];
    // if (!offset) offset = 0;
    return colours[(i + offset) % colours.length];
  }
  
  export function shrinkTextToFit(s: string, fit: number): string {
    let t = "";
  
    //common abbreviations
    s = s.replace(/ and /gi, " & ");
    if (s.length <= fit) return s;
  
    s = s.replace(/ ?the /gi, " ");
    if (s.length <= fit) return s;
  
    s = s.replace(/station/gi, "Stn");
    if (s.length <= fit) return s;
  
    s = s.replace(/hotel/gi, "Htl");
    if (s.length <= fit) return s;
  
    s = s.replace(/street/gi, "Str");
    if (s.length <= fit) return s;
  
    s = s.replace(/road/gi, "Rd");
    if (s.length <= fit) return s;
  
    s = s.replace(/airport/gi, "Airpt");
    if (s.length <= fit) return s;
  
    s = s.replace(/ park /gi, " Prk ");
    if (s.length <= fit) return s;
  
    //strip symbols
    while (s.length > fit) {
      t = s.replace(/[!'",.](?!.*[!'",.])/, "");
      if (t === s) break;
      s = t;
    }
  
    //strip vowels
    while (s.length > fit) {
      t = s.replace(/[aeiou](?!.*[aeiou])/, "");
      if (t === s) break;
      s = t;
    }
  
    //strip end of words
    while (s.length > fit) {
      let words = s.split(" ");
      for (let word = 0; word < words.length; word++) {
        if (word === 0) {
          if (words[word].length > 4)
            words[word] = words[word].substring(0, words[word].length - 1);
        } else {
          if (words[word].length > 3)
            words[word] = words[word].substring(0, words[word].length - 1);
        }
      }
      t = words.join(" ");
      // t = s.replace(/[aeiou](?!.*[aeiou])/, '');
      if (t === s) break;
      s = t;
    }
  
    //strip from end
    while (s.length > fit) {
      t = s.substring(0, s.length - 2);
      if (t === s) break;
      s = t;
    }
  
    return s;
  }
  
  export function addUrlQuery(key: string, value: string): void {
    if (window.history.replaceState) {
      let path = window.location.origin + window.location.pathname;
      if (!path.endsWith("?")) path += "?";
      const newurl = path + key + "=" + value + "&";
      window.history.replaceState(
        {
          path: newurl
        },
        "",
        newurl
      );
    }
  }