//@flow

import React from "react";
import css from "./genericinfo.module.scss";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/loading";
import Error from "../components/error";
import Logo from "./../images/idtv-logo/in-demand-tv-logo-2.png";
import { setPageMeta } from "../helpers";

const TERMS = gql`
  query GetChannelForTerms($domain: String!) {
    channels(domain: $domain) {
      id
      logo
      title
      licenseStatus
    }
  }
`;

export default function Terms(props: {domain:string}) {
  const history = useHistory();
  const { domain } = props;

  const { loading, error, data } = useQuery(TERMS, {
    variables: { domain: domain },
  });
  if (loading) return <Loading />;
  if (error || data.channels.length === 0) {
    console.error(error);
    return <Error />;
  }
const channel = data.channels[0];
setPageMeta(channel, "Terms and Conditions");

  return (
    <div className={css.wrapper}>
      <div className={css.back} onClick={history.goBack}>
        <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
      </div>
      <img className={css.logo} src={channel.logo || Logo} alt={channel.title}/>
      <h1>Terms of Use</h1>

      <p>
        {channel.title} provides a personalised one off Pay Per View Event service and subscription service that allows members to access content streamed over the Internet to certain Internet-connected
        TVs, computers and other devices ("{channel.title} ready devices").
      </p>
      <p>
        These Terms of Use govern your use of our service. As used in these Terms of Use, " {channel.title} service", "our service" or "the service" means the personalised service provided by In Demand
        TV or discovering and watching third party content, including all features and functionalities, recommendations and reviews, the website, and user interfaces, as well as all content and
        software associated with our service.
      </p>
      <ol>
        <li>
          <h3>Definitions</h3>
          <ol type="a">
            <li>Conditions: these conditions and any changes we may make to them.</li>
            <li>
              Contract: each contract between you and {channel.title} , of which the Conditions form part, authorising you to receive a third party Pay Per View Event(s); Third Party Subscriptions via
              the service.
            </li>
            <li>Event: each television programme or event offered to you by any third party via {channel.title} on a pay-per-view basis.</li>
            <li>
              Subscriptions: subscription service s that allows members ongoing access content streamed over the Internet to certain Internet-connected TVs, computers and other devices ("{channel.title}
              ready devices").
            </li>
            <li>
              Payment: the amount you must pay us for; each Event that you order; each subscription service you register for ongoing access. Payments for which maybe collected under an agreement
              between a member and a third party , at intervals determined by such third part y when registering.
            </li>
          </ol>
        </li>

        <li>
          <h3>Packages & Subscriptions</h3>
          <ol type="a">
            <li>All package purchases are for access to the package within the period that the purchase is valid. It does not grant ownership of the videos. Whilst {channel.title} will endeavour to keep the value of the content within any package as reasonably expected, {channel.title} can not guarantee that specific videos will remain in a package indefinitely. {channel.title} reserves the right to add or remove videos from any package at any time without warning.</li>
            
          </ol>
          </li>
        <li>
          <h3>General</h3>
          <ol type="a">
            <li>To use the {channel.title} service you must make the payment associated to your third party event or subscription and have Internet access and a {channel.title} ready device.</li>
            <li>
              The quality of the display of the content may vary from device to device, and may be affected by a variety of factors, such as your location, the bandwidth available through and/or speed
              of your Internet connection.
            </li>
            <li>
              Payment Methods. To use the {channel.title} service you must provide one or more Payment Methods. You authorise us to charge any Payment Method associated to your account in case your
              primary Payment Method is declined or no longer available to us for payment of your subscription fee. You remain responsible for any uncollected amounts. If a payment is not successfully
              settled, due to expiration, insufficient funds, or otherwise, and you do not cancel your account, we may suspend your access to the service until we have successfully charged a valid
              Payment Method. For some Payment Methods, the issuer may charge you certain fees, such as foreign transaction fees or other fees relating to the processing of your Payment Method. Local
              tax charges may vary depending on the Payment Method used. Check with your Payment Method service provider for details.
            </li>
            <li>
              You must be of a minimum age determined by your third party provider to access the content they provide. Where applicable m inors may only use the service under the supervision of an
              adult.
            </li>
          </ol>
        </li>

        <li>
          <h3>Events</h3>
          <ol type="a">
            <li>
              To purchase any Event, you must register for an account . Subject to these Conditions we will supply to you any Event that you order and you are permitted to receive via {channel.title}
              ready devices.
            </li>
            <li>If you purchase any Event by calling us by telephone, online, mobile or via SMS, where available, an administration charge may apply where this method of purchase if offered.</li>
            <li>
              If you purchase any Event then you may cancel your purchase of that Event up to 1 hour before the start time of that Event. If you purchase any Event and you tune into it for any length
              of time (however short), you will be charged in full for that Event even if you have previously requested a cancellation. Cancellations and refunds may not be processed until after the
              Event.
            </li>
            <li>
              We can cancel or withdraw any Event at any time. If we do, we shall try to advertise the cancellation or withdrawal on your screen and you will not be liable to pay for it. We may change
              the time at which any Event is shown, and if so, we will advertise the changed time on your screen and clause 3 (c) will apply to the new broadcast time.
            </li>
            <li>Event content purchased on an {channel.title} ready device will not be refunded if they cannot be viewed on another device.</li>
            <li>
              Event content purchased on a single pay-per-view ticket basis can only be viewed on one {channel.title} ready device at a time. You change which device is authorised to view pay-per-view
              events at any time. If you lose access to the Event content because you share or do not keep your ticket code secure you will not be issued another or be refunded.
            </li>
          </ol>
        </li>

        <li>
          <h3>Liability, Copying and Copyright</h3>
          <ol type="a">
            <li>
              We will not be liable under the Contract for any Event because of our failure to provide the Event for reasons outside our reasonable control or because of anything for which we have
              excluded liability.
            </li>
            <li>
              You must not do (or allow to be done) any of the following in respect of any Event:
              <ol type="i">
                <li>copy, redistribute or relay any of the content.</li>
                <li>sell or make any charge for watching any content.</li>
                <li>show any content in public to an audience, even if no charge is made.</li>
              </ol>
            </li>

            <li>{channel.title} and any third-party client retain full copyright to all content.</li>
          </ol>
        </li>
        <li>
          <h3>Disclaimers</h3>
          <ol type="a">
            <li>
              {channel.title} provides the services on an &ldquo;as is&rdquo; and &ldquo;as available&rdquo; basis. You use the services at your own risk . You must provide your own device and internet
              access.
            </li>
            <li>
              Except as expressly set forth herein, and to the extent permitted by applicable law, {channel.title} disclaims all warranties, express or implied, including implied warranties of
              merchantability, fitness for a particular purpose, and non-infringement. Among other things, {channel.title} makes no representations or warranties:
              <ol type="i">
                <li>
                  That our Services, or any part thereof, will be available or permitted in your jurisdiction, uninterrupted or error-free, completely secure, or accessible from all devices or
                  browsers;
                </li>

                <li>Concerning any content submitted by or actions of third party providers , including any thing offensive or that may not be suitable for anyone under the age of 18</li>

                <li>That we will continue to support any particular feature or maintain backwards compatibility with any third-party software or device; or</li>

                <li>Concerning any third-party websites and resources</li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
}
