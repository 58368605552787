//@flow
import React from "react";
import moment from "moment";

type Props = { enable: boolean, time: number, onSubmit: Function, clientID: string, isAdmin: boolean, sendmessage: string, userName: string, setMessage: Function };
type State = {};

class MessageBot extends React.Component<Props, State> {
  _makeGibberish(length: number) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnop qrstu vwxy z0123 456789";
    // var characters = "😎😎😎😎😎😎😎";

    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  _tick() {
    if (this.props.enable === false) return;
    const vars = {
      Body: this._makeGibberish(Math.floor(Math.random() * 120)),
      ClientDeviceID: "testbot",
      Nickname: this.props.userName + " [Bot]",
      Timestamp: moment().unix(),
    };
    this.props.onSubmit(vars);
    setTimeout(() => this._tick(), Math.random() * 1000 * this.props.time);
  }
  componentDidMount() {
    this._tick();
  }

  render() {
    return null;
  }
}

export default MessageBot;
