//@flow

import React from "react";
import css from "./channel.module.scss";
import { gql, useQuery } from "@apollo/client";
import Playlists from "../components/playlists-container";
import ChannelHeader from "../components/channelHeader";
// import { useParams } from "react-router-dom";
import Loading from "../components/loading";
import Error from "../components/error";
import License from "../components/license";
import { setPageMeta } from "../helpers";
import { connect } from "react-redux";

const CHANNEL = gql`
  query GetChannelGQL(
    $domain: String! # ,$embedType: String!
  ) {
    channels(domain: $domain) {
      id
      path
      domain
      playlistPrefix
      title
      licenseStatus
      # shortDescription
      longDescription
      headerVideo
      logo
      subscriptionText
      headerImages {
        id
        url
      }
    }
  }
`;

type Props = {
  domain: string,
  token: string
};
function Channel(props: Props) {
  // return <Error />;
  const { domain } = props;
  const { loading, error, data } = useQuery(CHANNEL, {
    variables: { domain: domain },errorPolicy: 'all'
  });
  if (loading) return <Loading />;
  if (error || data.channels.length === 0) {
    console.error(data);
    console.error(error);
    return <Error />;
  }
  // console.log(data);
  const channel = data.channels[0];
  setPageMeta(channel, "Home");
  return (
    <div className={css.wrapper}>
      <ChannelHeader {...props} channel={channel} />
      <Playlists channelPath={channel.path} />
      <License channel={channel} />
    </div>
  );
}


const mapStateToProps = (state /*, ownProps*/) => {
  const { auth } = state;
  // console.log(auth)
  return {
    token: auth.token,
  };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
