//@flow

import React, { useEffect, useRef, useState } from "react";
import shaka from "shaka-player/dist/shaka-player.ui";
import "shaka-player/dist/controls.css";

import css from "./shakaplayer.module.scss";

let isReloading = false;

export default function ShakaPlayer(props: { video: Object, channel: Object, poster: string, isLiveStream: boolean }) {
  const ref_video = useRef(null);
  const ref_container = useRef(null);
  const [isLoading, setisLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [controlsHidden, setControlsHidden] = useState(false);
  // const [isReloading, setIsReloading] = useState(false);
  // const [lasttimestamp, setLastTimestamp] = useState(0);

  window.refcontainer = ref_container;
  window.refvideo = ref_video;

  const { video, channel } = props;
  const src = video.endpoint;

  useEffect(() => {
    const player = new shaka.Player(ref_video.current);

    const reload = async () => {
      if (player) {
        console.log("reload...");
        try {
          // setHasError(false);
          await player.load(src);
        } catch (e) {
          isReloading = false;
          onError(e);
        }
      }
      // setIsReloading(false);
      isReloading = false;
    };

    let reloadTimer;
    let controlsTimer;

    const onError = async (err) => {
      console.error("sk Caught Error", err);
      if (err.detail && err.detail.code === 1002) {
        //network failed
        console.error("sk network failed");
      } else {
        setIsPlaying(false);
        //  await player.unload();
        // loading error maybe?  4015 = hls playlist invalid (not found)
        setHasError(true);
        if (isReloading === true) return;

        isReloading = true;
        clearTimeout(reloadTimer);
        reloadTimer = setTimeout(reload, 5000);
      }
    };

    player.addEventListener("error", onError);
    player.addEventListener("buffering", (e) => {
      //occurs a lot
      const bi = player.getBufferedInfo();
      console.log("sk buffering",bi,bi.total[0].end - bi.total[0].start);
      setisLoading(e.buffering);
      // if (e.buffering === false) ref_video.current && ref_video.current.play();
    });
    player.addEventListener("loading", () => {
      // this only occurs right at the start of loading
      console.log("sk loading");
      // setHasError(false);
      setisLoading(true);
    });
    player.addEventListener("loaded", () => {
      // this only occurs right at the start of loading
      console.log("sk loaded");
      // setHasError(false);
      setisLoading(false);
    });

    player.addEventListener("manifestparsed", () => {
      // this only occurs right at the start of loading
      console.log("sk manifestparsed");
      setHasError(false);
      // setisLoading(true);
    });

    // player.addEventListener("onstatechange", (e) => {
    //   console.log("sk statechange", e.state);
    // });
    // player.addEventListener("ratechange", (e) => {
    //   const r = e.target.getPlaybackRate();
    //   console.log("sk ratechange", r);
    //   if (r === 1) {
    //     // availabe rate is 1
    //     // setIsPlaying(true);
    //     // setHasError(false);
    //     // setisLoading(false);
    //   }
    // });
    async function load() {
      console.log("sk setup")
      shaka.polyfill.installAll();
      if (!shaka.Player.isBrowserSupported()) {
        console.error("Browser not supported!");
        return;
      }

      player.configure({
        streaming: {
          bufferingGoal: 8,
          rebufferingGoal: 2,
          bufferBehind: 30,
          jumpLargeGaps: true,
          failureCallback: (e) => {
            console.log("FAIL", e);
          },
          retryParameters: {
            backoffFactor: 2,
            baseDelay: 1000,
            fuzzFactor: 0.5,
            maxAttempts: 3,
            timeout: 6000,
          },
        },
      });
      window.player = player;

      // const uiConfig = {
      // addBigPlayButton: true,
      // controlPanelElements: ["mute", "volume", "time_and_duration", "fullscreen", "overflow_menu"],
      // };

      const ui = new shaka.ui.Overlay(player, ref_container.current, ref_video.current);
      // ui.configure(uiConfig);
      window.ui = ui;

      //       ref_video.current &&
      //       ref_video.current.addEventListener("stalled", () => {
      //         console.log("stalled");
      //       });

      //       ref_video.current &&
      //       ref_video.current.addEventListener("emptied", () => {
      //         console.log("emptied");
      //       });

      //       ref_video.current &&
      //       ref_video.current.addEventListener("suspend", () => {
      //         console.log("suspend");
      //       });

      //       ref_video.current &&
      //       ref_video.current.addEventListener("abort", () => {
      //         console.log("abort");
      //       });

            ref_video.current &&
              ref_video.current.addEventListener("loadstart", () => {
                console.log("sk loadstart");

                setisLoading(true);
              });

            ref_video.current &&
              ref_video.current.addEventListener("canplay", () => {
                console.log("canplay");
                ref_video.current && ref_video.current.play();
                // setisLoading(false);
                // setHasError(false);
              });

      // ref_video.current &&
      //   ref_video.current.addEventListener("playing", () => {
      //     console.log("playing");
      //   })
      ref_video.current &&
        ref_video.current.addEventListener("playing", () => {
          console.log("playing");
          setIsPlaying(true);
          clearTimeout(controlsTimer);
          controlsTimer = setTimeout(() => {
            setControlsHidden(true);
          }, 1500);
        });
      ref_video.current &&
        ref_video.current.addEventListener("pause", () => {
          console.log("pause");
          setIsPlaying(false);
        });
      ref_video.current &&
        ref_video.current.addEventListener("ended", () => {
          console.log("ended");
          setIsPlaying(false);
        });
        ref_video.current &&
        ref_video.current.addEventListener("streaming", () => {
          console.log("sk streaming");
          setIsPlaying(false);
        });
        ref_video.current &&
        ref_video.current.addEventListener("manifestparsed", () => {
          console.log("sk manifestparsed");
          setIsPlaying(false);
        });
      //       ref_video.current &&
      //         ref_video.current.addEventListener("ratechange", (e: Object) => {
      //           console.log("ratechange", e.target && e.target.playbackRate);
      //           if (e.target && e.target.playbackRate === 0) {
      // // this is effective for VODs
      //             // setisLoading(true);
      //             // setIsPlaying(false)

      //           } else {
      //             // setisLoading(false);
      //             // setIsPlaying(true)
      //           }
      //         });

      //       ref_video.current &&
      //         ref_video.current.addEventListener("waiting", (e) => {
      //           console.log("waiting",e);
      //           setisLoading(true);
      //         });

      ref_container.current &&
        ref_container.current.addEventListener("mouseleave", () => {
          console.log("mouseleave");
          setControlsHidden(true);
        });

      ref_container.current &&
        ref_container.current.addEventListener("mousemove", () => {
          setControlsHidden(false);
          clearTimeout(controlsTimer);
          controlsTimer = setTimeout(() => {
            setControlsHidden(true);
          }, 3000);
        });

      try {
        // ref_video.current && ref_video.current.load();
        await player.load(src);
      } catch (e) {
        onError(e);
      }
    }
    load();
    return () => {
      clearTimeout(reloadTimer);
      clearTimeout(controlsTimer);
      player.destroy();
    };
  }, [src]);

  // console.log(props)
  return (
    <div ref={ref_container} className={css.wrapper}>
      <video
        ref={ref_video}
        poster={props.poster}
        // controls={false}
        preload="auto"
        // style={{backgroundImage:`url(${props.poster})`}}
        // autoPlay={false}
      ></video>
      <Control video={ref_video.current} isLoading={isLoading} hasError={hasError} isPlaying={isPlaying} controlsHidden={controlsHidden} />
      <div className={css.logo}>
        <img src={channel.logo} alt="logo" />
      </div>
    </div>
  );
}

function Control(props: { video: Object, isLoading: boolean, hasError: boolean, isPlaying: boolean, controlsHidden: boolean }) {
  const { isPlaying, isLoading, hasError, controlsHidden } = props;

  return (
    <div className={css.control}>
      <div className={css.loadingBackdrop} style={{ opacity: isLoading || hasError ? 1 : 0 }}></div>
      {!hasError && isLoading ? <Spinner /> : null}
      {hasError ? (
        <div className={css.offline}>
          <div>OFFLINE</div>
        </div>
      ) : null}
      {!isLoading && !hasError && !isPlaying ? <PlayButton /> : null}
      {!isLoading && !hasError && isPlaying ? <PauseButton hide={controlsHidden} /> : null}
    </div>
  );
}

function PlayButton() {
  return (
    <div className={css.playButton}>
      <i className="fa fa-play-circle"></i>
    </div>
  );
}

function PauseButton(props: { hide: boolean }) {
  return (
    <div className={css.pauseButton} style={{ opacity: props.hide ? 0 : 1 }}>
      <i className="fa fa-pause-circle"></i>
    </div>
  );
}
function Spinner() {
  return (
    <div className={css.spinner}>
      <svg width="38" height="38" viewBox="0 0 38 38" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="8.042%" y1="0%" x2="65.682%" y2="23.865%" id="a">
            <stop stopColor="#fff" stopOpacity="0" offset="0%" />
            <stop stopColor="#fff" stopOpacity=".631" offset="63.146%" />
            <stop stopColor="#fff" offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)">
            <path d="M36 18c0-9.94-8.06-18-18-18" id="Oval-2" stroke="url(#a)" strokeWidth="2">
              <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
            </path>
            <circle fill="#fff" cx="36" cy="18" r="1">
              <animateTransform attributeName="transform" type="rotate" from="0 18 18" to="360 18 18" dur="0.9s" repeatCount="indefinite" />
            </circle>
          </g>
        </g>
      </svg>
    </div>
  );
}
