//@flow

import React from "react";
// import css from "./channel.module.scss";
import { gql, useQuery } from "@apollo/client";
import Playlist from "./playlist";
import PlaylistSingle from "./playlist-single-item";

// import { useParams } from "react-router-dom";
import Loading from "./loading";
import Error from "./error";

const PLAYLISTS = gql`
  query GetPlaylists(
    $channelpath: String! # ,$embedType: String!
  ) {
    channels(path: $channelpath) {
      id
      playlists {
        id
        videos {
          id
        }
      }
    }
  }
`;

type Props = {
  channelPath: string,
};
export default function Channel(props: Props) {
  // console.log("render playlists")
  // return <Loading/>
  const { channelPath } = props;
  const { loading, error, data } = useQuery(PLAYLISTS, {
    variables: { channelpath: channelPath },
    // fetchPolicy: "no-cache"
  });
  if (loading) return <Loading />;
  if (error ){console.error(error) ;return <Error />;}

  const channel = data.channels[0];
  if (!channel) return null;
  return (
    <React.Fragment>
      {channel.playlists.map((playlist) => {
        return playlist.videos.length === 0 ? null: playlist.videos.length === 1 ? <PlaylistSingle playlistID={playlist.id} key={playlist.id} /> : <Playlist playlistID={playlist.id} key={playlist.id} />;
      })}
    </React.Fragment>
  );
}
