//@flow
import React from "react";
import css from "./auth.module.scss";

type Props = {};

export default function Loading(props: Props) {
  return (
    <div className={css.panel}>
      <div className={css.loading}>
        <i className="fa fa-sync fa-spin"></i>
      </div>
    </div>
  );
}
