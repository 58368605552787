//@flow
import React from "react";
import css from "./auth.module.scss";

type Props = { channel: Object, title: string, errorCode: number, error: string };

export default function PanelHeader(props: Props) {

  // const head = props.channel && props.channel.logo ?
  // <div className={css.channelLogo}>
  //         <img src={props.channel.logo} alt="logo" />
  //       </div> :null;
  return (
    <div className={css.header}>
      {/* {head} */}
      {(props.channel && props.channel.title) || "In Demand TV"}
      <div className={css.title}>{props.title}</div>
      <div className={css.error}>{props.errorCode === 0 ? <span>&nbsp;</span> : props.error}</div>
    </div>
  );
}
