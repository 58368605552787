//@flow
import React from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { connect } from "react-redux";
import { setUsername, setHideChat, setEditing, addMention, setMessage, setScrollMode, setUserInterrupt, setChatConnected,setAdmin } from "./redux/actions";
import Layout from "./layout";
import randomName from "./names";
// import store from "./redux/reducer";

export type Message = {
  Body: string,
  ClientDeviceID: string,
  Nickname: string,
  Timestamp: string,
  RoomID: string,
};

const chatbotName = "ChatBot";

type Props = {
  admin: boolean,
  popoutMode?: boolean,
  roomID: string,
  roomName: string,
  username: string,
  hideChat: boolean,
  setHideChat: Function,
  setUsername: Function,
  setMessage: Function,
  setChatConnected: Function,
  chatConnected: boolean,

};
type State = {
  messages: Array<Message>,
  webSocketSupported: boolean,
  clientID: string
};

const maxmessages = 200;

const chatroomNode = "wss://chatroom-node.service.indemand.digital/ws";
// const chatroomNode = "ws://192.168.1.108:9005/ws";

class Chatroom extends React.Component<Props, State> {
  constructor(props: Object) {
    super(props);
    const supported = window["WebSocket"] !== undefined;
    let clientID = localStorage.getItem("@clientid");
    if (!clientID) {
      clientID = uuidv4();
      localStorage.setItem("@clientid", clientID);
    }

    const nickname = localStorage.getItem("@nickname");
    this.props.setUsername(nickname ? nickname : randomName() + "_" + clientID.substr(0, 4));

    this.state = {
      webSocketSupported: supported,
      messages: [],
      clientID: clientID
    };
  }

  // conn: *;//using window

  _appendLog(item: Message) {
    let { messages } = this.state;
    if (messages.length > maxmessages) {
      messages = messages.slice(messages.length - maxmessages / 4, messages.length);
    }
    this.setState({ messages: [...messages, item] });
    // if (messages.length > 4) {
    //   messages = messages.slice(0,4);
    // }
    // this.setState({ messages: [item,...messages] });
  }

  _connectChat() {
    if (this.state.webSocketSupported) {
      if (window.chatSocket !== null) {
        window.chatSocket && window.chatSocket.close();
        window.chatSocket = null;
      }
      window.chatSocket = new WebSocket(chatroomNode);

      window.chatSocket.onopen = () => {
        console.log("WS OPEN");
        // const m: Message = {
        //   Body: "Chat Connected",
        //   ClientDeviceID: this.state.clientID,
        //   Nickname: chatbotName,
        //   Timestamp: moment().unix(),
        //   RoomID: this.props.roomID,
        // };
        // this._appendLog(m);

        const mInit: Message = {
          Body: "__init__",
          ClientDeviceID: this.state.clientID,
          Nickname: chatbotName,
          Timestamp: moment().unix().toString(),
          RoomID: this.props.roomID,
        };

        window.chatSocket && window.chatSocket.send(JSON.stringify(mInit));

        this.props.setChatConnected(true);
      };
      window.chatSocket.onclose = () => {
        console.log("WS CLOSE");
        // const m: Message = {
        //   Body: "LiveChat Disconnected",
        //   ClientDeviceID: this.state.clientID,
        //   Nickname: chatbotName,
        //   Timestamp: moment().unix(),
        //   RoomID: this.props.roomID,
        // };
        // this._appendLog(m);
        this.props.setChatConnected(false);

        window.chatSocket = null;
        setTimeout(() => this._connectChat(), 5000);
      };
      window.chatSocket.onerror = (e) => {
        console.error("WS ERROR", e);
        // const m: Message = { Body: "LiveChat Error", ClientDeviceID: this.state.clientID, Nickname: chatbotName, Timestamp: moment().unix() };
        // this._appendLog(m);
        // this.setState({ chatConnected: false });
        // window.chatSocket = null;
        // setTimeout(() => this._connectChat(), 5000);
      };

      window.chatSocket.onmessage = (evt: Object) => {
        // console.log(evt.data);
        const data: string = evt.data;
        var messages = data.split("\n");
        // console.log(messages);

        for (var i = 0; i < messages.length; i++) {
          // console.log("J:",messages[i])
          try {
            const m = JSON.parse(messages[i]);
            // if (m.ClientDeviceID !== this.state.clientID) this._appendLog(m);
            this._appendLog(m);
          } catch (e) {}
        }
      };
    } else {
      const m: Message = {
        Body: "Your browser does not support Chat",
        ClientDeviceID: this.state.clientID,
        Nickname: chatbotName,
        Timestamp: moment().unix(),
        RoomID: this.props.roomID,
      };
      this._appendLog(m);
    }
  }
  componentWillUnmount() {
    window.chatSocket && window.chatSocket.close();
    window.chatSocket = null;
  }
  componentDidMount() {
    this._connectChat();
  }

  onSubmit(payload: Message) {
    payload.Timestamp = payload.Timestamp.toString();
    // payload.ClientDeviceID = this.state.clientID;
    // console.log(payload);
    if (!window.chatSocket) {
      return;
    }
    if (!payload.Body) {
      return;
    }
    payload.RoomID = this.props.roomID;
    // payload.Body = this.props.roomName + payload.Body
    if (this.props.chatConnected) window.chatSocket.send(JSON.stringify(payload));
    this.props.setMessage("");
  }

  componentDidCatch() {
    console.error("catch error");
  }
  render() {
    return (
      <Layout
        {...this.props}
        messages={this.state.messages}
        clientID={this.state.clientID}
        chatBotName={chatbotName}
        roomName={this.props.roomName}
        onSubmit={(payload) => this.onSubmit(payload)}
        userName={this.props.username}
        popoutMode={this.props.popoutMode === true ? true : false}
      />
    );
  }
}

const mapStateToProps = (state_ /*, ownProps*/) => {
  const state = state_.chat;
  return {
    username: state.userName,
    hideChat: state.hideChat,
    editing: state.editing,
    sendmessage: state.sendmessage,
    scrollMode: state.scrollMode,
    userInterrupt: state.userInterrupt,
    chatConnected: state.chatConnected,
    isAdmin: state.isAdmin
  };
};

const mapDispatchToProps = {
  setUsername,
  setHideChat,
  setEditing,
  addMention,
  setMessage,
  setScrollMode,
  setUserInterrupt,
  setChatConnected,
  setAdmin
};

export default  connect(mapStateToProps, mapDispatchToProps)(Chatroom);

// export default function wrapped(props: Object) {
//   return (
//     // <Provider store={store}>
//       <ChatContainer {...props} />
//     // </Provider>
//   );
// }
