//@flow

import React from "react";
import css from "./license.module.scss";

export default function License(props: { channel: Object }) {
  const { channel } = props;
  if (channel.licenseStatus === "ACTIVE") return null;
  return (
    <div className={css.licenseMessage}>
      <div>This channel is running in {channel.licenseStatus.toLowerCase()} mode</div>
    </div>
  );
}
