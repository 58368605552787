//@flow
import React, { useEffect } from "react";
// import store from "./redux/reducer";
import { connect } from "react-redux";
import SignUp from "./signup";
import SignIn from "./signin";
import { doSignUp, doSignIn, doValidateToken, setScreen, setShowSplash, doForgotPassword, doResetCode, doResetPassword, doVerifyEmail } from "./redux/actions";
import css from "./auth.module.scss";
import TVPlayer from "./../components/tvplayer";
import SplashScreen from "./splashscreen";
import ForgotPass from "./forgotpassword";
import ResetPass from "./resetpassword";
import ResetCode from "./resetcode";
import Loading from "./loading";
import CheckEmail from "./checkemail";
import EmailVerified from "./emailverifyok";
import { gql, useQuery } from "@apollo/client";
import { getChannel, getUrlParameter } from "../helpers";
import Logo from "../components/logo";
import Preview from "./preview";
import {history} from '../app';

type Props = {
  children: *,
  signedIn: Boolean,
  screen: string,
  error: string,
  errorCode: number,
  doSignIn: Function,
  doSignUp: Function,
  doValidateToken: Function,
  loading: boolean,
  setScreen: Function,
  showSplashScreen: boolean,
  setShowSplash: Function,
  doForgotPassword: Function,
  doResetCode: Function,
  doResetPassword: Function,
  totp: string,
  resetEmail: string,
  doVerifyEmail: Function,
};

const CHANNEL = gql`
  query GetChannelForAuth(
    $domain: String! # ,$embedType: String!
  ) {
    channels(domain: $domain) {
      id
      path
      title
      headerVideo
      logo
      longDescription
    }
  }
`;

function Auth(props: Props) {
  const { signedIn, doValidateToken, doVerifyEmail, loading, showSplashScreen, setShowSplash, screen } = props;

  const domain =  getChannel();
  const isVerifying = window.location.pathname.includes("verify");
  // const ch = ;

  useEffect(() => {
    const token = localStorage.getItem("@token");
    if (signedIn && token ) return;
    // console.log(signedIn,token)
    if (isVerifying) {
      if (screen !== "EMAILVERIFIED") {
        const t = getUrlParameter("t");
       
        doVerifyEmail(t, domain);
      }
    } else if (token) {
      // if a token is present we attempt to log in
      // console.log('validate token...')
      doValidateToken(token);
    } else {
      // shows default screen - which is preview or sign in
      setShowSplash(false);
    }
  });

  // set a screen if there's an auth action in the url, then remove param from url
  useEffect(() => {
    if (signedIn) return;
    const urlAction = getUrlParameter("authaction");
    if (urlAction) {
      console.log(urlAction);
      if (urlAction === "signin") props.setScreen("SIGNIN");
      history.push("/")
      setShowSplash(false);
    }
  });


  // redirect a logged-out preview to the sign in screen if no channel is specified
  // useEffect(() => {
  //   // console.log(channelpath)
  //   if (signedIn) return;
  //   if (channelpath === "channels" && props.screen === "PREVIEW") {
  //     props.setScreen("SIGNIN");
  //   }
  // });

  const gqldata = useQuery(CHANNEL, {
    variables: { domain: domain },
    fetchPolicy: "no-cache"
  });

  // if (window.location.pathname.includes("verify"))return <Loading text="Verifying email..."/>;

  const { error, data } = gqldata;
  if (error) {
    console.log(error);
    // return <div>Something went wrong :(</div>;
  }
  const chan = data && data.channels[0];

  if (signedIn) return <React.Fragment>{props.children}</React.Fragment>;
  // console.log("doing auth stuff", screen, isVerifying, props);
  if (showSplashScreen) return <SplashScreen />;
  // if (isVerifying) return <Loading text="verifying email..." />;

  const style = chan ? null : { backgroundImage: `url(/shared/bg2.jpg)` };
  return (
    <div className={css.auth}>
      <div className={css.backdrop} style={style}>
        {chan && <TVPlayer hlsURL={chan.headerVideo || "https://d2vz0x5krocupv.cloudfront.net/vidx/775b909835955ecf4bf4c151/playlist.m3u8"} />}
      </div>
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          <Preview {...props} channel={chan} waitingForChannel={gqldata.loading} />
          <SignUp {...props} channel={chan} />
          <SignIn {...props} channel={chan} />
          <ForgotPass {...props} channel={chan} />
          <ResetPass {...props} channel={chan} />
          <ResetCode {...props} channel={chan} />
          <CheckEmail {...props} channel={chan} />
          <EmailVerified {...props} channel={chan} />
          <Logo alt={0} width={100} />
        </React.Fragment>
      )}
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  const { auth } = state;
  return {
    signedIn: auth.signedIn,
    token: auth.token,
    screen: auth.screen,
    error: auth.error,
    errorCode: auth.errorCode,
    showSplashScreen: auth.showSplashScreen,
    resetEmail: auth.resetEmail,
    totp: auth.totp,
    loading: auth.loading,
  };
};

const mapDispatchToProps = {
  doSignUp: doSignUp,
  doSignIn: doSignIn,
  doValidateToken: doValidateToken,
  setScreen: setScreen,
  setShowSplash: setShowSplash,
  doForgotPassword: doForgotPassword,
  doResetCode: doResetCode,
  doResetPassword: doResetPassword,
  doVerifyEmail: doVerifyEmail,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
