//@flow
import React, { useState } from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";
import { getChannel } from "../helpers";

type Props = {  channel:Object, screen: string, errorCode: number, error: string,doResetPassword:Function ,resetEmail:string,totp:string};

export default function ResetPassword(props: Props) {
  const { screen ,doResetPassword,resetEmail,totp} = props;
  const [newpass, setNewPass] = useState();
  if (screen !== "RESETPASS") return null;

  function keyDown(e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") doResetPassword(newpass, resetEmail, getChannel(), totp);
  }

  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Enter a new password" />
      <form>
        <div className={css.detail}>Create a new password at least 8 characters long</div>

        <div className={css.field}>
          <input autoComplete="new-password" name="password" required={true} type="password" placeholder="New password" onChange={(e) => setNewPass(e.target.value)} onKeyDown={keyDown}></input>
        </div>
        <div className={css.button} onClick={() => doResetPassword(newpass, resetEmail, getChannel(), totp)}>
          Submit
        </div>
      </form>
    </div>
  );
}
