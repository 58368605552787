//@flow
import React from "react";

import css from "./app.module.scss";
import { createBrowserHistory } from "history";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import Channel from "./pages/channel";
// import ChannelMenu from "./pages/channelmenu";
import Video from "./pages/video";
import Footer from "./components/footer";
import Support from "./pages/support";
import Account from "./pages/account";
import Packages from "./pages/packages";
import Purchase from "./pages/purchase";

import PlaylistPage from "./pages/playlistpage";
import { getAPIURL } from "./helpers";
import { setContext } from "@apollo/client/link/context";

import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from "@apollo/client";
import { ScrollToTop, getChannel } from "./helpers";

import ChatPopup from "./pages/chatpopup";
import Auth from "./auth";
import { Provider } from "react-redux";
import store from "./redux/rootreducer";
import GA from "./components/googleanalytics";
import Terms from "./pages/terms";
// import SessionManager from "./components/sessionManager";
import { forceSignOut } from "./auth/redux/actions";

import { onError } from "@apollo/client/link/error";

const errorLink = onError((data) => {
const  { graphQLErrors, networkError } = data;
  console.log(data)
  if (graphQLErrors) graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`));

  if (networkError) {
    console.log(networkError);
    if (networkError.message === "Failed to fetch"){
      // window.alert("Sorry, can't connect to the server")
      console.error("Sorry, can't connect to the server")
    }else
    if (networkError.response && networkError.response.status === 403) {
      //force logout
      forceSignOut()
    }
  }
});

const httpLink = createHttpLink({
  uri: getAPIURL() + "/query",
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("@token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Auth: token ? `${token}` : "",
    },
  };
});

export const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export const history = createBrowserHistory();

function Navigation() {
  const domain = getChannel();
  return (
    <Router history={history}>
      {GA.init() && <GA.RouteTracker />}
      {/* <SessionManager /> */}
      {/* <Route  path="/" render={(props) => console.log(props)} /> */}

      <div className={css.app}>
        <ScrollToTop />
        <Switch>
          {/* / home route doesn't run any auth checks
          it assumes signed in/out based on presence of token
          ONLY to render suitable labels
          same goes for footer */}
          {/* <Route exact path="/" render={() => <Home />} /> */}
          <Route path="/support" render={() => <Support domain={domain} />} />
          <Route path="/terms" render={() => <Terms domain={domain} />} />
          {/* auth always matched top level switch */}
          <Auth>
            <Switch>
              <Route path="/account" render={() => <Account domain={domain} />} />
              <Route path="/chat/:videoid" render={() => <ChatPopup domain={domain} />} />
              <Route path="/video/:videoid" render={() => <Video domain={domain} />} />
              <Route path="/packages/:videoid" render={() => <Packages domain={domain} />} />
              <Route path="/purchase/:packageid" render={() => <Purchase domain={domain} />} />

              <Route path="/playlist/:playlistid" render={() => <PlaylistPage domain={domain} />} />
              <Route path="/" render={() => <Channel domain={domain} />} />
            </Switch>
          </Auth>
          <Redirect to="/" />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

function App() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </ApolloProvider>
  );
}

export default App;
