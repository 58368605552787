//@flow
import React, { useState } from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";
import { getChannel } from "../helpers";

type Props = { channel: Object, screen: string, doSignUp: Function, setScreen: Function, errorCode: number, error: string };

export default function SignUp(props: Props) {
  const { screen, doSignUp, setScreen, errorCode } = props;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [optIn, setOptIn] = useState(0);

  function keyDown(e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") doSignUp(email, getChannel(), password);
  }

  if (screen !== "SIGNUP") return null;
  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Create Account" />
      <form autoComplete="on">
        <div className={css.detail}>It's free to create an account, and you can watch as much of our free content as you want!</div>
        <div className={css.detail}>*Some content is pay-per-view</div>

        <div className={css.field}>
          <input autoFocus={true} autoComplete="email" name="email" required={true} type="email" placeholder="Your email" onChange={(e) => setEmail(e.target.value)} onKeyDown={keyDown}></input>
        </div>
        <div className={css.field}>
          <input autoComplete="new-password" name="password" required={true} type="password" placeholder="Set a password" onChange={(e) => setPassword(e.target.value)} onKeyDown={keyDown}></input>
          <div className={css.info}>*must be at least 8 characters</div>
        </div>
        <div className={css.inlineField}>
          <input className={css.checkbox} name="marketing" type="checkbox" onChange={(e) => setOptIn(e.target.value)}></input>
          <div className={css.info}>I'd like to receive news & offers as well as important messages </div>
        </div>
        <div className={css.buttonrow}>
          <div className={css.button} onClick={() => doSignUp(email, getChannel(), password, optIn)}>
            Submit
          </div>
          {errorCode === 409 ? (
            <div className={css.button} onClick={() => setScreen("FORGOTPASS")}>
              Forgot Password?
            </div>
          ) : null}
        </div>
        <div className={css.hook} onClick={() => setScreen("SIGNIN")} style={{ cursor: "pointer" }}>
          Back To Sign In
        </div>
      </form>
    </div>
  );
}
