//@flow

import React from "react";
import css from "./playlistpage.module.scss";
import { gql, useQuery } from "@apollo/client";
// import Playlists from "../components/playlists-container";
import PlaylistHeader from "../components/playlistHeader";
// import { useParams } from "react-router-dom";
import Loading from "../components/loading";
import Error from "../components/error";
import License from "../components/license";
import { setPageMeta } from "../helpers";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import VideoGrid from "../components/videogrid";

const CHANNEL = gql`
  query GetPlaylistPageGQL($domain: String!, $playlistid: Int!) {
    channels(domain: $domain) {
      id
      path
      domain
      playlistPrefix
      title
      licenseStatus
      # shortDescription
      longDescription
      headerVideo
      logo
      subscriptionText
      headerImages {
        id
        url
      }
    }
    playlist(id: $playlistid) {
      id
      title
      description
      videos {
        id
        title
        imageThumb
        imageLarge
        duration
        videoSource
        streamName
        posterImageEdge{
        # images{
          image{
            id x100 
            x1200 x400
          }
        }
      }
    }
  }
`;

type Props = {
  domain: string,
  token: string,
};
function PlaylistPage(props: Props) {
  // return <Loading/>
  const { domain } = props;
  const { playlistid } = useParams();
  const { loading, error, data } = useQuery(CHANNEL, {
    variables: { domain: domain, playlistid: playlistid },
  });
  if (loading) return <Loading />;
  if (error || !data.playlist) {
    console.error(data);
    console.error(error);
    return <Error />;
  }

  const channel = data.channels[0];
  const playlist = data.playlist;

  setPageMeta(channel, playlist.title);
  return (
    <div className={css.wrapper}>
      <PlaylistHeader {...props} playlist={playlist} channel={channel} />
      <VideoGrid videos={playlist.videos} />
      <License channel={channel} />
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  const { auth } = state;
  // console.log(auth)
  return {
    token: auth.token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistPage);
