//@flow
import React, { useState } from "react";
import css from "./editor.module.scss";
import moment from "moment";


export default function Editor(props: Object) {
  // _setNickname(e: SyntheticInputEvent<*> | SyntheticKeyboardEvent<*>, button: boolean) {
  //   if (e.key === "Enter" || button) {
  //     localStorage.setItem("@nickname", this.state.nickname);
  //     localStorage.setItem("@nicknameupdated", moment().unix());
  //     this.setState({ settingNickname: false });
  //   }
  // }

  const [pass, setPass] = useState();
  const [showAdmin, setShowAdmin] = useState(false);

  const { userName, setUsername, editing, scrollMode,setAdmin ,setEditing} = props;

  function handleScrollpick(e) {
    props.setScrollMode(e);
  }

  const now = moment().unix();
  const lastUpdated = Number(localStorage.getItem("@nicknameupdated"));
  const showclass = editing === true ? css.show : null;
  // console.log(scrollMode);
  // if (now - lastUpdated > 20 * 60)
  return (
    <div className={[css.editor, showclass].join(" ")}>
      <h1>Settings</h1>
      <section>
        <h3>Username</h3>
        <div className={css.desc}>
          Max 12 characters.
          <br />
          You can't change your username too often.
        </div>

        <div className={css.namerow}>
          <input
            className={css.input}
            type="text"
            disabled={now - lastUpdated < 20 * 60}
            placeholder="nickname"
            value={userName}
            maxLength={12}
            onChange={(e) => setUsername(e.target.value)}
            onKeyDown={(e) => setUsername(e.target.value)}
          />

          <div
            className={css.button}
            onClick={(e) => {
              localStorage.setItem("@nicknameupdated", moment().unix());
              setEditing(false)
            }}
          >
            SAVE&nbsp;<i title="Save" className="fa fa-check"></i>
          </div>
        </div>
      </section>

      <section className={css.scrollOptions}>
        <h3>Auto-scroll</h3>
        <div className={css.desc}>Control how fast the chat window scrolls</div>
        <div className={scrollMode === 2 ? css.selected : css.unselected} onClick={() => handleScrollpick(2)}>
          Real-Time Updates
        </div>
        <div className={scrollMode === 1 ? css.selected : css.unselected} onClick={() => handleScrollpick(1)}>
          Slow-Scroll
        </div>
        <div className={scrollMode === 0 ? css.selected : css.unselected} onClick={() => handleScrollpick(0)}>
          Auto Scroll Off
        </div>
      </section>
      <div onClick={() => setShowAdmin(!showAdmin)}>
        <i title="Admin options" className={`fa ${showAdmin ? "fa-chevron-circle-down" : "fa-chevron-circle-right"}`}></i>
      </div>
      <section className={showAdmin ? css.adminPanelShow : css.adminPanelHide}>
        <h3>Admin</h3>
        <div className={css.desc}>Enter password to enable admin features</div>
        <div className={css.namerow}>
          <input
            className={css.input}
            type="password"
            placeholder=""
            // value={pass}
            onChange={(e) => setPass(e.target.value)}
            // onKeyDown={(e) => handleAuth(e)}
          />

          <div
            className={css.button}
            onClick={(e) => {
              setAdmin(pass);
            }}
          >
            SAVE&nbsp;<i title="Save" className="fa fa-check"></i>
          </div>
        </div>
      </section>
    </div>
  );
}
