//@flow

import React, { useState } from "react";
import css from "./purchase.module.scss";
import { gql, useQuery } from "@apollo/client";
import { useParams, useHistory } from "react-router-dom";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Loading from "../components/loading";
import { setPageMeta } from "../helpers";
import Error from "../components/error";
// import Thumb from "../components/chatroom";
import License from "../components/license";

const stripePromise = loadStripe("pk_test_51H7eTkAOVXEs2vsI8NI0aGhwP1vwcKlOF997T0rncL0kflNBgNRg2h1RByOi7lczCnboqeDJgDoKSWABDCHbQ6PQ00Al5YZpqd");

const PURCHASE = gql`
  query GetPurchaseData($domain: String!, $paywallid: ID) {
    user {
      id
      email
    }
    channels(domain: $domain) {
      id
      logo
      path
      domain
      title
      licenseStatus
      paywalls(id: $paywallid) {
        id
        title
        description
        ticketTypes {
          id
          title
          value
          recurInterval
        }
      }
    }
  }
`;

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};

type Props = { domain: string };

export default function Purchase(props: Props) {
  const { paywallid } = useParams();
  const history = useHistory();
  const [termsOK, setTermsOK] = useState(false);
  const { domain } = props;
  const { loading, error, data } = useQuery(PURCHASE, {
    variables: { domain: domain, paywallid: paywallid },
  });

  if (loading) return <Loading />;
  if (error) {
    console.error(error);
    return <Error />;
  }
  if (data.channels.length === 0) {
    console.error(error);
    return <Error text="That channel doesn't exist" />;
  }

  // console.log(data)
  // const { video } = data;
  const channel = data.channels[0];
  const paywall = channel.paywalls[0];
  const ticketType = paywall.ticketTypes[0];
  const { user } = data;
  // const isLiveStream = video.videoSource === "live";
  setPageMeta(channel, paywall.title);

  let cost = `£${ticketType.value / 100}`;
  if (ticketType.recurInterval) {
    cost += `/${ticketType.recurInterval}`;
  } else {
    cost += " one-off payment";
  }

  return (
    <Elements stripe={stripePromise}>
      <div className={css.wrapper}>
        <div className={css.header}>
          <div className={css.back} onClick={() => history.goBack()}>
            <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
          </div>
        </div>

        <h2>{channel.title}</h2>

        <div className={css.titleWrapper}>
          <div className={css.paywallTitle}>{paywall.title}</div>
          <div className={css.price}>{cost}</div>
          <div className={css.ticketTitle}>{ticketType.title}</div>
          <div className={css.price}>{user.email}</div>
        </div>

        <div className={css.terms}>
          <input type="checkbox" value={termsOK} onChange={(e)=>{setTermsOK(e.target.checked)}}/>&nbsp;I confirm that I have read and understood the <a href="/terms">terms and conditions.</a>
        </div>

        <CheckoutForm disable={!termsOK}/>
        <License channel={channel} />
      </div>
    </Elements>
  );
}

const CheckoutForm = (props: {disable:bool}) => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  // Handle real-time validation errors from the card Element.
  const handleChange = (event) => {
    if (event.error) {
      setError(event.error.message);
    } else {
      setError(null);
    }
  };

  // Handle form submission.
  const handleSubmit = async (event) => {
    event.preventDefault();
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      // Inform the user if there was an error.
      setError(result.error.message);
    } else {
      setError(null);
      // Send the token to your server.
      stripeTokenHandler(result.token);
    }
  };

  return (
    <form onSubmit={handleSubmit} >
      <div className="form-row">
        <label htmlFor="card-element">Credit or debit card</label>
        <CardElement id="card-element" options={CARD_ELEMENT_OPTIONS} onChange={handleChange} />
        <div className="card-errors" role="alert">
          {error}
        </div>
      </div>
      <button type="submit" disabled={props.disable}>Submit Payment</button>
      {props.disable ? <div className={css.formdim}></div>:null}
    </form>
  );
};

// POST the token ID to your backend.
async function stripeTokenHandler(token) {
  const response = await fetch("/charge", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: token.id }),
  });

  return response.json();
}
