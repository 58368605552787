//@flow

import { combineReducers,createStore } from 'redux'
import chat from '../components/chatroom/redux/reducer';
import auth from '../auth/redux/reducer';

const reducers = combineReducers({
auth,chat
})

export default createStore(reducers)
