//@flow
import React from "react";
import MessageComposer from "./composer";
import MessageList from "./messagelist";
import css from "./layout.module.scss";
import Editor from "./editor";
import Header from "./header";
import Bot from "./bot";

export default function Layout(props: Object) {
  const { hideChat, setHideChat } = props;
  // console.log(props.isAdmin)

  return (
    <div className={[hideChat ? css.hide : null, css.container].join(" ")}>
      {hideChat ? (
        <div className={css.compressed} onClick={() => setHideChat(false)}>
          <i title="Show Chat" className="fa fa-eye"></i>
          <div className={css.vertical}>LIVE CHAT</div>
        </div>
      ) : (
        <React.Fragment>
          <div className={css.fixedHeader}>
            <Header {...props} />
            <Editor {...props} />

          </div>

          <MessageList {...props} />
          <MessageComposer {...props} />
          <Bot {...props} time={12} enable={false}/>
        </React.Fragment>
      )}
    </div>
  );
}
