//@flow
import React from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";
// import { getUrlParameter } from "../helpers";

type Props = { channel: Object, screen: string, errorCode: number, error: string, setScreen: Function };

export default function EmailVerifyOK(props: Props) {
  const {  screen, errorCode } = props;
  // const [newpass, setNewPass] = useState();
  if (screen !== "EMAILVERIFIED") return null;
  // console.log("verify email screen")

  // const c = getUrlParameter("c");

  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Email Verified" />
      <form>
        {errorCode === 0 ? (
          <h4>
            Email verified <i className="fa fa-check-circle"></i>
          </h4>
        ) : (
          <h4>
            Email verification error <i className="fa fa-times-circle"></i>
          </h4>
        )}
        <a className={css.button} href={"/?authaction=signin"}>
          Sign In
        </a>
      </form>
    </div>
  );
}
