//@flow

import React from "react";
import css from "./logo.module.scss";
import Logo from "./../images/idtv-logo/in-demand-tv-logo-2.png";
import Logo2 from "./../images/idtv-logo/in-demand-tv-logo-4.png";


export default function Logo_(props: { width?:number ,alt?:number}) {

  
  return (
    <div className={css.logowrapper}>
    <img src={props.alt === 1 ? Logo2:Logo} alt="In Demand TV" style={{maxWidth:`${props.width || 300}px`}}/>
    </div>
  );
}
