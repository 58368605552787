//@flow
import React, { useState } from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";
import { getChannel } from "../helpers";

type Props = { channel:Object,  screen: string, doForgotPassword: Function, setScreen:Function,errorCode: number, error: string };

export default function ForgotPassword(props: Props) {
  const { screen, doForgotPassword,setScreen} = props;

  const [email, setEmail] = useState();

  function keyDown(e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") doForgotPassword(email, getChannel());
  }

  if (screen !== "FORGOTPASS") return null;
  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Forgot Your Password?" />
      <form>
        <div className={css.detail}>Enter the email address you signed up with and we'll send you a reset code.</div>

        <div className={css.field}>
          <input autoComplete="email" name="email" required={true} type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} onKeyDown={keyDown}></input>
        </div>

        <div className={css.button} onClick={() => doForgotPassword(email, getChannel())}>
          Send Reset Code
        </div>
        <div className={css.hook} onClick={() => setScreen("SIGNIN")} style={{ cursor: "pointer" }}>
          Back To Sign In
        </div>
      </form>
    </div>
  );
}
