//@flow
import React, {  } from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";

type Props = { channel:Object,  screen: string, errorCode: number, error: string,setScreen:Function };

export default function ResetPassword(props: Props) {
  const { setScreen,screen} = props;
  // const [newpass, setNewPass] = useState();
  if (screen !== "CHECKYOUREMAIL") return null;



  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Verify Your Email" />
      <form>
      <h4>Account created!</h4>

      <h4>Please check your email.</h4>
      <h4>Click the link in the email to verify your account.</h4>

        <div className={css.button} onClick={() => setScreen("SIGNIN")}>
          Sign In
        </div>
      </form>
    </div>
  );
}
