//@flow
import React from "react";
import css from "./playlist.module.scss";
import Thumbnail from "./thumbnail";
import Loading from "../components/loading";
import Error from "../components/error";
import { gql, useQuery } from "@apollo/client";
import { Link } from "react-router-dom";

const PLAYLIST = gql`
  query GetPlaylist($id: Int!) {
    playlist(id: $id) {
      id
      title
      description
      videos {
        id
        title

        imageThumb
        imageLarge
        endpoint
        platform
        posterImageEdge {
          image {
            id
            x100
            x400
            x1200
          }
        }
        duration
        videoSource
        streamName
        # shortDescription
        paywallEdges {
          paywall {
            id
            # title
            callToActionTag
            # tickets {
            #   id
            #   type {
            #     title
            #     value
            #   }
            # }
          }
        }
        userEdge {
          id
          viewedPercent
          hasAccess
        }
      }
    }
  }
`;

export default function PlaylistWrapper(props: { playlistID: number }) {
  const { loading, error, data } = useQuery(PLAYLIST, {
    variables: { id: props.playlistID },
  });
  if (loading) return <Loading text="Loading videos..." />;
  if (error) {
    console.error(error);
    return <Error />;
  }

  const playlist = data.playlist;
  // console.log(playlist);
  return <Playlist playlist={playlist} />;
}

type Props = {
  playlist: Object,
};
type State = {
  hasNext: boolean,
  hasPrev: boolean,
};
class Playlist extends React.Component<Props, State> {
  scrollWrapper: *;
  handleResize: Function;

  constructor(props: Object) {
    super(props);
    this.scrollWrapper = React.createRef();
    this.state = { hasNext: false, hasPrev: false };
    this.handleResize = () => {
      this.setScrollHandles();
    };
  }

  setScrollHandles() {
    // const scrollerWidth = this.props.playlist.videos.length * thumbWidth;
    const scrollerWidth = (this.scrollWrapper.current && this.scrollWrapper.current.scrollWidth) || 0;
    const windowWidth = window.innerWidth;
    const scrollPosition = (this.scrollWrapper.current && this.scrollWrapper.current.scrollLeft) || 0;
    // console.log(scrollerWidth,windowWidth,scrollPosition)
    const hasNext = scrollerWidth - scrollPosition > windowWidth;
    const hasPrev = (this.scrollWrapper.current && this.scrollWrapper.current.scrollLeft > 0) || false;
    this.setState({
      hasNext: hasNext,
      hasPrev: hasPrev,
    });
  }

  componentDidMount() {
    // seems i have to call this after the dom has had chance to load
    setTimeout(() => this.setScrollHandles(), 1000);
    window.addEventListener("resize", this.handleResize);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleNext() {
    this.scrollWrapper.current &&
      this.scrollWrapper.current.scrollBy({
        left: window.innerWidth * 0.8,
        top: 0,
        behavior: "smooth",
      });
  }
  handlePrev() {
    this.scrollWrapper.current &&
      this.scrollWrapper.current.scrollBy({
        left: window.innerWidth * -0.8,
        top: 0,
        behavior: "smooth",
      });
  }

  render() {
    const { playlist } = this.props;

    const { hasNext, hasPrev } = this.state;

    if(!playlist)return null;
    
    return (
      <div className={css.wrapper}>
        <Link to={`/playlist/${playlist.id}`}>
          <div className={css.title}>
            <i className="fa fa-stream"></i> {playlist.title}
          </div>

          {playlist.description && <div className={css.description}>{playlist.description}</div>}
        </Link>
        <div className={css.thumbs}>
          <div className={css.scrollContainer} ref={this.scrollWrapper} onScroll={() => this.setScrollHandles()}>
            <div className={css.thumbnails}>
              {playlist.videos.map((v) => {
                if (v.platform === "IDTV" && !v.endpoint) return null;
                return <Thumbnail video={v} key={v.id} />;
              })}
            </div>
          </div>

          <div className={css.prev} style={{ opacity: hasPrev ? 1 : 0 }} onClick={() => this.handlePrev()}>
            <i className="fa fa-chevron-circle-left"></i>
          </div>

          <div className={css.next} style={{ opacity: hasNext ? 1 : 0 }} onClick={() => this.handleNext()}>
            <i className="fa fa-chevron-circle-right"></i>
          </div>
        </div>
      </div>
    );
  }
}
