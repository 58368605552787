//@flow

import React from "react";
import css from "./chatpopup.module.scss";
import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
// import SVPPlayer from "../components/svpplayer";
import Loading from "../components/loading";
// import { getAPIURL } from "../helpers";
import Error from "../components/error";
import Chat from "../components/chatroom";
import { useEffect } from "react";

const CHATROOM = gql`
  query GetChatroom($videoid: ID!) {
    video(id: $videoid) {
      id
      title
    }
  }
`;

type Props = {};
export default function ChatPopup(props: Props) {
  const { videoid } = useParams();

  useEffect(() => {
    window.resizeTo(360, 800);
  });

  const { loading, error, data } = useQuery(CHATROOM, {
    variables: { videoid: videoid },
  });

  if (loading) return <Loading />;
  if (error || !data.video) {console.error(error);return <Error />;}

  const { video } = data;

  return (
    <div className={css.wrapper}>
      <Chat admin={false} roomID={videoid} roomName={video.title} popoutMode={true} />
    </div>
  );
}
