//@flow
import React, { useState } from "react";
import PanelHeader from "./panel-header";
import css from "./auth.module.scss";
import { getChannel } from "../helpers";

type Props = {  channel:Object, screen: string, errorCode: number, error: string,doResetCode:Function,resetEmail:string };

export default function ResetCode(props: Props) {
  const { screen ,doResetCode,resetEmail} = props;
  const [code, setCode] = useState();
  if (screen !== "RESETCODE") return null;

  function keyDown(e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") doResetCode(code, resetEmail, getChannel());
  }

  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Enter Your Reset Code" />
      <form>
        <div className={css.detail}>A reset code has been sent to your email account & will be valid for 5 mins. Do not refresh this page.</div>
        <div className={css.field}>
          <input name="code" required={true} type="text" placeholder="Enter Code" onChange={(e) => setCode(e.target.value)} onKeyDown={keyDown}></input>
        </div>
        <div className={css.button} onClick={() => doResetCode(code, resetEmail, getChannel())}>
          Submit
        </div>
      </form>
    </div>
  );
}
