//@flow

import React from "react";
import css from "./video.module.scss";
import { gql, useQuery } from "@apollo/client";
import { useParams, useHistory, Redirect } from "react-router-dom";
import SVPPlayer from "../components/svpplayer";
import ShakaPlayer from "../components/shakaplayer";

import Loading from "../components/loading";
import { setPageMeta } from "../helpers";
import Error from "../components/error";
import Chat from "./../components/chatroom";
import License from "../components/license";

const VIDEO = gql`
  query GetChannelForVideo(
    $domain: String!
    $videoid: ID! # ,$embedType: String!
  ) {
    video(id: $videoid) {
      id
      title
      duration
      clipKey
      longDescription
      imageLarge
      type
      posterImageEdge {
        image {
          id
          x1200
        }
      }
      videoSource
      platform
      endpoint
      encodeVersion
      enableChat
      # embedCode(embedType: "js_embed")
      userEdge {
        id
        viewedPercent
        hasAccess
      }
    }
    channels(domain: $domain) {
      id
      logo
      path
      domain
      title
      licenseStatus
      headerVideo #temp
    }
  }
`;

type Props = { domain: string };
export default function Video(props: Props) {
  const { videoid } = useParams();
  const history = useHistory();
  const { domain } = props;
  const { loading, error, data } = useQuery(VIDEO, {
    variables: { domain: domain, videoid: videoid, embedType: "popin" },
  });

  if (loading) return <Loading />;
  if (error) {
    console.error(error);
    return <Error />;
  }
  if (data.channels.length === 0) {
    console.error(error);
    return <Error text="That channel doesn't exist" />;
  }
  if (!data.video) {
    console.error(error);
    return <Error text="That video doesn't exist" />;
  }

  const { video } = data;

  if (!video.userEdge.hasAccess) {
    // console.error(error);
    return <Redirect to={`/packages/${video.id}`} />;
  }
  console.log(video)

  const channel = data.channels[0];
  const isLiveStream = video.videoSource === "live" || video.type === "LIVE";
  setPageMeta(channel, video.title);

  const image =
    (video.posterImageEdge && video.posterImageEdge.image.x1200) ||
    video.imageLarge ||
    "https://images.indemand.tv/aHR0cHM6Ly8yMDE3NTgtNjI0MDI5LXJhaWtmY3F1YXhxbmNvZnFmbS5zdGFja3BhdGhkbnMuY29tL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE3LzAzL2ltZy1wbGFjZWhvbGRlci0zMDB4MTg5LnBuZw==";
  return (
    <div className={css.wrapper}>
      <div className={css.backdropContainer}>
        <div className={css.backdrop} style={{ backgroundImage: `url(${image})` }}></div>
      </div>

      <div className={css.leftPanel}>
        <div className={css.header}>
          <div className={css.back} onClick={() => history.goBack()}>
            <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
          </div>
          {/* <div className={css.logo}> */}
          <a href={`/`} className={css.logo}>
            <img src={channel.logo} alt="logo" />
          </a>
          {/* </div> */}
        </div>

        <div className={css.titleWrapper}>
          {isLiveStream && (
            <div className={css.livestream}>
              <i className="fa fa-video"></i>&nbsp;&nbsp;&nbsp;LIVE STREAM
            </div>
          )}
          <div className={css.title}>{video.title}</div>
        </div>

        <div className={css.content}>
          <div className={css.playerbox}>{video.platform === "SVP" ? <SVPPlayer id={video.clipKey} /> : <ShakaPlayer video={video} poster={image} channel={channel} isLiveStream={isLiveStream}/>}</div>
        </div>

        <div className={css.infoBox}>
          <div className={css.desc} dangerouslySetInnerHTML={{ __html: video.longDescription }}></div>
        </div>
      </div>

      {video.enableChat && (
        <div className={css.rightPanel}>
          <Chat admin={false} roomID={videoid} roomName={video.title} />
        </div>
      )}
      <License channel={channel} />
    </div>
  );
}
