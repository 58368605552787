//@flow

import React from "react";
import css from "./account.module.scss";
import { gql, useQuery } from "@apollo/client";
// import Playlists from "../components/pla/ylists-container";
// import ChannelHeader from "../components/channelHeader";
// import { useParams } from "react-router-dom";
import Loading from "../components/loading";
import Error from "../components/error";
import License from "../components/license";
import { getPrettyDate, setPageMeta } from "../helpers";
import { connect } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Logo from "./../images/idtv-logo/in-demand-tv-logo-2.png";

const ACCOUNT = gql`
  query GetAccountGQL($domain: String!) {
    channels(domain: $domain) {
      id
      logo
      title
      licenseStatus
    }
    user {
      id
      email
      emailVerified
      created
      marketingOptIn
      tickets {
        type {
          id
          title
        }
        id
        soldOn
        soldValue
        expires
        isValid
        paywall {
          id
          title
        }
      }
    }
  }
`;

type Props = {
  domain: string,
  token: string,
};
function Channel(props: Props) {
  const history = useHistory();

  // return <Loading/>
  const { domain } = props;
  const { loading, error, data } = useQuery(ACCOUNT, {
    variables: { domain: domain },
  });
  if (loading) return <Loading />;
  if (error) {
    console.error(data);
    console.error(error);
    return <Error />;
  }
  console.log(data);
  const channel = data.channels[0];
  const user = data.user;

  setPageMeta(channel, "Account");
  return (
    <div className={css.wrapper}>
      <div className={css.header}>
        <div className={css.back} onClick={history.goBack}>
          <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
        </div>
        <img className={css.logo} src={channel.logo || Logo} alt={channel.title} />
      </div>
      <h1>Account Details</h1>
      <table>
        <tbody>
          <Field name="Primary email">
            {user.email}
            {user.emailVerified ? null : " (Not verified)"}
          </Field>

          <Field name="Account created">{moment(user.created).format("DD/MM/YY")}</Field>
          <Field name="Receive marketing">{user.marketingOptIn ? "yes" : "no"}</Field>
        </tbody>
      </table>
      <hr />
      {user.tickets.length === 0 ? null : (
        <React.Fragment>
          <h3>Purchases</h3>
          <table className={css.tickets}>
            <thead>
              <tr>
                <th>Order ID</th>
                <th>Status</th>
                <th>Item</th>
                <th>Type</th>
                <th>Value</th>
                <th>Purchase date</th>
                <th>Expires</th>
              </tr>
            </thead>
            <tbody>
              {user.tickets.map((t) => (
                <Ticket ticket={t} key={t.id} user={user} />
              ))}
            </tbody>
          </table>
        </React.Fragment>
      )}
      <License channel={channel} />
    </div>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  const { auth } = state;
  return {
    token: auth.token,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);

function Field(props: { name: *, children: * }): Object {
  return (
    <tr className={css.field}>
      <td className={css.name}>{props.name}</td>
      <td className={css.value}>{props.children}</td>
    </tr>
  );
}

function Ticket(props: { ticket: Object, user: Object }): Object {
  const { ticket, user } = props;
  return (
    <tr>
      <td>
        1798-{user.id}-{ticket.id}
      </td>
      <td>{ticket.isValid ? "ACTIVE" : "NOT AVAILABLE"}</td>
      <td>{ticket.paywall.title}</td>
      <td>{ticket.type.title}</td>
      <td>£{ticket.soldValue / 100 || "n/a"}</td>
      <td>{getPrettyDate(ticket.soldOn) || "n/a"}</td>
      <td>{ticket.expires || "never"}</td>
    </tr>
  );
}
