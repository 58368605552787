//@flow
import React from "react";
import css from "./auth.module.scss";
import Loading from "../components/loading";

type Props = {};

export default function SplashScreen(props: Props) {
  return (
    <div className={css.splashScreen}>
      <Loading text="Loading..." />
    </div>
  );
}
