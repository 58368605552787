//@flow

import React, { useState } from "react";
import css from "./channelheader.module.scss";
import TVPlayer from "./tvplayer";

type Props = {
  channel: Object,
  token: string
};
export default function ChannelHeader(props: Props) {
  const { channel } = props;
  const [subText, setSubText] = useState(<div>Watch Now</div>);
  const [subOpacity, setSubOpacity] = useState(1);
  const [subOn, setSubOn] = useState(false);
  const [subFlx, setSubFlx] = useState(0);

  const doStx = () => {
    setSubOpacity(0);
    if (!subOn) setSubFlx(1);

    setTimeout(() => {
      setSubText(
        !subOn ? (
          <React.Fragment>
            <div>
              <b>Just click on any video below - it's all free to watch!</b>
            </div>
            {/* <div>If you need a pay-per-view ticket to watch it, the options pop up automatically.</div> */}
            {/* <div>You only pay for what you watch!</div> */}
          </React.Fragment>
        ) : (
          <div>Watch Now</div>
        )
      );
      if (subOn) setSubFlx(0);
      setSubOpacity(1);
      setSubOn(!subOn);
    }, 400);
  };

  const img = channel.headerImages[0] && channel.headerImages[0].url;
  return (
    <div className={css.wrapper}>
      <div
        className={css.background}
        // style={{ backgroundImage: `url(${img})` }}
      >
        {channel.headerVideo && <TVPlayer hlsURL={`${channel.headerVideo}`} />}
        {/* {channel.headerVideo && <TVPlayer hlsURL={`${channel.headerVideo}_${props.token}`} />} */}

        {/* {channel.headerVideo && <TVPlayer hlsURL={`http://localhost:8081/775b909835955ecf4bf4c151_${"123"}`} />} */}
        <div className={css.fader}></div>
        <div className={css.fader2}></div>
      </div>
      <div className={css.foreground}>
        <div className={css.logo}>
          <img src={channel.logo} alt="logo" />
        </div>
        <div className={css.channelTitle}>{channel.title}</div>
        <div className={css.channelDesc} style={{ maxHeight: subFlx !== 0 ? `0px`:"200px" }} dangerouslySetInnerHTML={{ __html: channel.longDescription }}></div>
        <div className={css.channelSubscription}>{channel.subscriptionText}</div>
        <div className={css.subscribeNow} style={{ flexGrow: subFlx }} onClick={() => doStx()}>
          <div className={css.inner} style={{ opacity: subOpacity }}>
            {subText}
          </div>
        </div>
      </div>
    </div>
  );
}
