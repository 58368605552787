//@flow
import React from "react";
import css from "./messagelist.module.scss";
import type { Message } from "./index";
// import moment from "moment";
import MessageRow from "./message";
// import {setHideChat} from './../../redux/actions';
import Loading from "./../loading";

const userScrollTimeout = 8;
type Props = {
  popoutMode: boolean,
  messages: Array<Message>,
  roomName: string,
  roomID: string,
  addMention: Function,
  scrollMode: number,
  userInterrupt: boolean,
  setUserInterrupt: Function,
  chatBotName: string,
  clientID: string,
  isAdmin: boolean,
  username: string
};
type State = { isReady: boolean };

class MessageList extends React.Component<Props, State> {
  state = { isReady: false };
  userScrolledTimeout: *;
  isScrolling = false;
  listRef: *;

  constructor(props: Object) {
    super(props);
    this.listRef = React.createRef();
  }

  _userScrollDetected() {
    this.props.setUserInterrupt(true);
    clearTimeout(this.userScrolledTimeout);
    this.userScrolledTimeout = setTimeout(() => {
      this.props.setUserInterrupt(false);
      this._smoothScrollTo(0, this.listRef.current);
    }, userScrollTimeout * 1000);
  }

  componentWillUnmount(){
    this.listRef.current &&  this.listRef.current.removeEventListener("wheel", () => this._userScrollDetected());
    this.listRef.current && this.listRef.current.removeEventListener("touchstart", () => this._userScrollDetected());

  }
  componentDidMount() {
 setTimeout(() => this._init(), 1000);
  }

  _init() {
    this.setState({isReady:true})
    if (this.listRef.current && this.props.messages && this.props.messages.length > 20) {
      this.listRef.current.scrollTop = this.listRef.current.scrollHeight;
    }
    //start autoscroll
    setTimeout(() => this._smoothScrollTo(0, this.listRef.current), 1000);

    this.listRef.current && this.listRef.current.addEventListener("wheel", () => this._userScrollDetected());
    this.listRef.current && this.listRef.current.addEventListener("touchstart", () => this._userScrollDetected());
   
  }
  _smoothScrollTo(timestamp: number, e: Object) {
    if (this.props.scrollMode === 0 || this.props.userInterrupt === true) return;
    try {
      if (e) {
        const delta = window._getDelta(e.scrollHeight, e.scrollTop, this.props.scrollMode);
        // console.log(e.scrollHeight,e.scrollTop,this.props.scrollMode,delta);
        e.scrollTop = e.scrollTop + delta;
      }
    } catch (err) {
      console.error(err);
    }
    window.requestAnimationFrame((s) => this._smoothScrollTo(s, e));
  }

  render() {
    // console.log(this.props.isAdmin)
    if (this.state.isReady === false) return <Loading text="Loading Chat" theme="light"/>;
    const messages = this.props.messages.map((m, i) => {
      return (
        <MessageRow
          key={m.Timestamp + m.ClientDeviceID + m.Body}
          message={m}
          isBot={m.Nickname === this.props.chatBotName}
          isMe={m.ClientDeviceID === this.props.clientID}
          isAdmin={this.props.isAdmin}
          addMention={this.props.addMention}
          username={this.props.username}
        />
      );
    });

    // const { popoutMode } = this.props;

    // for when theres no messages
    // const style = popoutMode === true ? { height: "600px" } : {};

    return (
      <div className={css.messagesContainer} >
        <div className={css.list} ref={this.listRef} 
        // style={style}
        >
          {messages}
        </div>
      </div>
    );
  }
}

export default MessageList;
