//@flow
import React from // useState
"react";
import css from "./thumbnail.module.scss";
import { Link } from "react-router-dom";
import moment from "moment";
// import { getAPIURL } from "../helpers";

export default function Thumbnail(props: { video: Object, scale?: number, showTitle?: boolean, fullWidthMobile?: boolean }) {
  const { video } = props;
  const hasAccess = !video.userEdge || video.userEdge.hasAccess === true;

  const firstPaywall = video.paywallEdges && video.paywallEdges.length > 0 && video.paywallEdges[0].paywall;
  // console.log(video)
  const callToAction: string = (firstPaywall && firstPaywall.callToActionTag) || "Subscribe";

  const scale = props.scale ? props.scale : 1;
  const showTitle = props.showTitle === false ? false : true;
  const fullWidthMobile = props.fullWidthMobile ? props.fullWidthMobile : false;

  const defaultSizePX = 100;
  // const [isHovered, setIsHovered] = useState(false);
  const { posterImageEdge } = video;

  let thumbSrc = "https://images.indemand.tv/aHR0cHM6Ly8yMDE3NTgtNjI0MDI5LXJhaWtmY3F1YXhxbmNvZnFmbS5zdGFja3BhdGhkbnMuY29tL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE3LzAzL2ltZy1wbGFjZWhvbGRlci0zMDB4MTg5LnBuZw==";
  if (posterImageEdge) {
    thumbSrc = posterImageEdge.image.x400;
  } else if (video.imageThumb) thumbSrc =  video.imageThumb;
  const thumb = <img src={thumbSrc} className={[css.image, fullWidthMobile ? css.fullWidthMobile : null, hasAccess ? null : css.unavailableImg].join(" ")} alt={video.title}></img>;

  // const thumbZoomScale = 1.06;
  const isLiveStream = video.videoSource === "live";

  // const style = isHovered ? `scale3d(${thumbZoomScale},${thumbZoomScale},${thumbZoomScale})` : `scale3d(1,1,1)`;

  const time = moment.duration(video.duration, "seconds");
  let timeStr = "";
  if (time.asSeconds() < 60) {
    // under 1 min
    timeStr = `${String(time.seconds())}s`;
  } else if (time.asSeconds() < 3600) {
    //under 1 hour
    timeStr = `${time.minutes()}:${String(time.seconds()).padStart(2, "0")}`;
  } else {
    timeStr = `${time.hours()}:${String(time.minutes()).padStart(2, "0")}:${String(time.seconds()).padStart(2, "0")}`;
  }

  return (
    <Link to={hasAccess ? `/video/${video.id}` : `/packages/${video.id}`}>
      <div className={[css.wrapper, hasAccess ? null : css.unavailableWrapper].join(" ")}>
        <div
          className={[css.thumbwrapper, fullWidthMobile ? css.fullWidthWrapperMobile : null].join(" ")}
          style={{ height: `${defaultSizePX * scale}px` }}
          // onMouseEnter={() => setIsHovered(true)}
          // onMouseLeave={() => setIsHovered(false)}
        >
          {thumb}
        </div>
        {hasAccess !== true ? <div className={css.subscribeTag}>{callToAction}</div> : null}

        {showTitle ? <div className={[css.title, fullWidthMobile ? css.fullWidthTitleMobile : null].join(" ")}>{video.title}</div> : null}
        {showTitle ? <div className={[css.time, fullWidthMobile ? css.fullWidthTimeMobile : null].join(" ")}>{isLiveStream ? "" : timeStr}</div> : null}
      </div>
    </Link>
  );
}
