//@flow

import React, { useRef, useEffect } from "react";
import css from "./tvplayer.module.scss";
import shaka from "shaka-player/dist/shaka-player.ui";
import "shaka-player/dist/controls.css";

function onErrorEvent(event) {
  console.error("Error code", event.detail.code, "object", event.detail);
}

type Props = {
  hlsURL: string,
};

export default function TVPlayer(props: Props) {
  const ref_video = useRef(null);
  const ref_container = useRef(null);

  const { hlsURL } = props;
  const isHoriztonalViewport = window.innerWidth / window.innerHeight > 1.67;

  useEffect(() => {
    shaka.polyfill.installAll();
    if (!shaka.Player.isBrowserSupported()) {
      console.error("Browser not supported!");
      return;
    }

    const player = new shaka.Player(ref_video.current);
    player.configure({
      streaming: {
        bufferingGoal: 30,
        rebufferingGoal: 1,
        bufferBehind: 15,
      },
    });
    window.player = player;


    const uiConfig = {
      addSeekBar: false,
      controlPanelElements: [],
    };

    const ui = new shaka.ui.Overlay(player, ref_container.current, ref_video.current);
    ui.configure(uiConfig);

    const controls = ui.getControls();
   controls.setEnabledShakaControls(false)

    player.addEventListener("error", onErrorEvent);

    try {
      player.load(hlsURL);
    } catch (e) {
      onErrorEvent(e);
    }
  
  
  
  
  }, [hlsURL]);

  return (
    <div
      className={css.container}
      onClick={(e) => {
        this.play(e);
      }}
      ref={ref_container}
    >
      <video
        style={{ width: isHoriztonalViewport ? "100%" : "unset", height: isHoriztonalViewport ? "unset" : "100%" }}
        playsInline={true}
        loop={true}
        controls={false}
        ref={ref_video}
        autoPlay={true}
        muted={true}
      />
    </div>
  );
}
