//@flow

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './serviceWorker';
import 'whatwg-fetch'
import 'promise-polyfill/src/polyfill';
import muxjs from "mux.js";
window.muxjs = muxjs;

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

     
       
        ;
      
   ;
       
         
          ;
        
     
       
         
          ;
        
     
     
       
        ;
      
   