//@flow
import React, { useState } from "react";
import css from "./auth.module.scss";
import PanelHeader from "./panel-header";
import { getChannel } from "../helpers";

type Props = { channel:Object,  screen: string, doSignIn: Function, errorCode: number, error: string, setScreen: Function };

export default function SignIn(props: Props) {
  const { screen, doSignIn,  setScreen } = props;

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  function keyDown(e) {
    if (e.key === "Enter" || e.key === "NumpadEnter") doSignIn(email, getChannel(), password);
  }

  if (screen !== "SIGNIN") return null;

  return (
    <div className={css.panel}>
      <PanelHeader {...props} title="Sign In" />
      <p className={css.notetext}>Please note - if you've previously created an account for another channel (such as Reminisce TV), data privacy means you will need to <span onClick={() => setScreen("SIGNUP")}>create a new account</span> for In Demand TV. It's still 100% free!</p>
      <form autoComplete={"on"}>
        <div className={css.field}>
          <input autoComplete="email" name="email" autoFocus={true} required={true} type="email" placeholder="email" onChange={(e) => setEmail(e.target.value)} onKeyDown={keyDown}></input>
        </div>
        <div className={css.field}>
          <input autoComplete="password" name="password" required={true} type="password" placeholder="password" onChange={(e) => setPassword(e.target.value)} onKeyDown={keyDown}></input>
        </div>
        <div className={css.button} onClick={() => doSignIn(email, getChannel(), password)}>
          Sign In
        </div>
        <div className={css.hook}>First time? Create a free account to start watching now!</div>
        <div className={css.buttonrow}>
          <div className={css.buttonSmall} onClick={() => setScreen("SIGNUP")}>
            Create Free Account
          </div>
        </div>
        <div className={css.hook} onClick={() => setScreen("FORGOTPASS")} style={{ cursor: "pointer" }}>
          Forgot Password?
        </div>
      </form>
    </div>
  );
}
