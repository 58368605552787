//@flow

import React from "react";
import Thumbnail from "./thumbnail";
import css from "./videogrid.module.scss";

export default function VideoGrid(props: { videos: Array<Object> }) {
  const videos = props.videos.map((v) => <Thumbnail key={v.id} video={v} fullWidthMobile={true} />);
  return <div className={css.wrapper}>{videos}</div>;
}
