//@flow
import React from "react";
import css from "./header.module.scss";

type Props = {
  popoutMode: boolean,
  clientID: string,
  chatBotName: string,
  roomName: string,
  roomID: string,
  setHideChat: Function,
  hideChat: boolean,
  setEditing: Function,
  editing: boolean,
  addMention: Function,
  slowScroll: boolean,
  setSlowScroll: Function,
  chatConnected: boolean,
};

export default function Header(props: Props) {
  const { popoutMode, editing, chatConnected } = props;
  const highlighted = editing === true ? css.highlighted : null;
  const indicator = chatConnected === true ? css.indicatorOn : css.indicatorOff;

  return (
    <div className={css.header}>
      <div className={css.roomtitle}>
        LIVE CHAT
        <div className={indicator}>
          <i title="Hide Chat" className="fa fa-circle"></i>
        </div>
        <br />
        <b>{props.roomName}</b>
      </div>
      <div className={css.controls}>
        <div className={[highlighted, css.button].join(" ")} onClick={() => props.setEditing(!props.editing)}>
          <i title="Chat Settings" className={`fa ${editing? "fa-times-circle":"fa-cog"}`}></i>
        </div>
        {!popoutMode ? (
          <div
            className={css.button}
            onClick={() => {
              window.open(`/chat/${props.roomID}`, "Live Chat", "width=360,height=700");
              props.setHideChat(true);
            }}
          >
            <i title="Open chat in new window" className="fa fa-external-link"></i>
          </div>
        ) : null}
        {!popoutMode ? (
          <div className={css.button} onClick={() => props.setHideChat(!props.hideChat)}>
            <i title="Hide Chat" className="fa fa-eye-slash"></i>
          </div>
        ) : null}
      </div>
      {/* <div className={css.fader}></div> */}
    </div>
  );
}
