//@flow
// user actions
export const DO_SIGN_UP = "DO_SIGN_UP";
export const DO_SIGN_IN = "DO_SIGN_IN";
export const DO_SIGN_OUT = "DO_SIGN_OUT";
export const DO_VALIDATE_TOKEN = "DO_VALIDATE_TOKEN";
export const DO_FORGOT_PASSWORD = "DO_FORGOT_PASSWORD";
export const DO_RESET_CODE = "DO_RESET_CODE";
export const DO_RESET_PASSWORD = "DO_RESET_PASSWORD";
export const DO_VERIFY_EMAIL = "DO_VERIFY_EMAIL";

export const SET_SCREEN = "SET_SCREEN";

// Network async actions
export const RECEIVE_SIGN_UP = "RECEIVE_SIGN_UP";
export const RECEIVE_SIGN_IN = "RECEIVE_SIGN_IN";
export const RECEIVE_VALIDATE_TOKEN = "RECEIVE_VALIDATE_TOKEN";
export const RECEIVE_FORGOT_PASSWORD = "RECEIVE_FORGOT_PASSWORD";
export const RECEIVE_RESET_CODE = "RECEIVE_RESET_CODE";
export const RECEIVE_RESET_PASSWORD = "RECEIVE_RESET_PASSWORD";
export const RECEIVE_VERIFY_EMAIL = "RECEIVE_VERIFY_EMAIL";

// when we can't connect to auth server etc
export const NETWORK_ERROR = "NETWORK_ERROR";

export const SET_SHOW_SPLASHSCREEN = "SET_SHOW_SPLASHSCREEN";
