//@flow

import React from "react";
import css from "./playlistheader.module.scss";
// import TVPlayer from "./tvplayer";
// import { getAPIURL } from "../helpers";
import { useHistory } from "react-router-dom";

type Props = {
  channel: Object,
  playlist: Object,
  token: string,
};
export default function ChannelHeader(props: Props) {
  const { playlist, channel } = props;
  const history = useHistory();
  console.log(playlist);

  const video = playlist?.videos[0] || null;
  // let headerImg = "https://images.indemand.tv/aHR0cHM6Ly8yMDE3NTgtNjI0MDI5LXJhaWtmY3F1YXhxbmNvZnFmbS5zdGFja3BhdGhkbnMuY29tL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE3LzAzL2ltZy1wbGFjZWhvbGRlci0zMDB4MTg5LnBuZw==";
  // if (firstvideo?.imageLarge) headerImg = firstvideo.imageLarge;
  // if(firstvideo?.posterImage?.image?.x1200)headerImg = firstvideo?.posterImage?.image?.x1200
  const headerImg = (video.posterImageEdge && video.posterImageEdge.image.x1200 )||video.imageLarge || "https://images.indemand.tv/aHR0cHM6Ly8yMDE3NTgtNjI0MDI5LXJhaWtmY3F1YXhxbmNvZnFmbS5zdGFja3BhdGhkbnMuY29tL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE3LzAzL2ltZy1wbGFjZWhvbGRlci0zMDB4MTg5LnBuZw=="


  return (
    <div className={css.wrapper}>
      <div className={css.background}>
        <img src={headerImg} alt={playlist.title} />
        <div className={css.fader}></div>
        <div className={css.fader2}></div>
      </div>
      <div className={css.foreground}>
        <div className={css.header}>
          <div className={css.back} onClick={() => history.goBack()}>
            <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
          </div>
          <a href={`/`} className={css.logo}>
            <img src={channel.logo} alt="logo" />
          </a>
        </div>
        <div>
          <div className={css.channelTitle}>{channel.title}</div>
          <div className={css.playlistTitle}>{playlist.title}</div>
          <div className={css.channelDesc} dangerouslySetInnerHTML={{ __html: playlist.description }}></div>
        </div>
      </div>
    </div>
  );
}
