//@flow
import React from "react";
import css from "./composer.module.scss";
import moment from "moment";

type Props = { onSubmit: Function, clientID: string, isAdmin: boolean ,sendmessage:string,userName:string,setMessage:Function};
type State = {  };

class MessageComposer extends React.Component<Props, State> {
  _onSubmit(e?: SyntheticInputEvent<*>) {
    e && e.stopPropagation();
    e && e.preventDefault();
    const vars = {
      Body: this.props.sendmessage,
      ClientDeviceID: this.props.isAdmin ? this.props.clientID+"_admin" : this.props.clientID,
      Nickname: this.props.userName,
      Timestamp: moment().unix(),
      // RoomID: "theroomID",
    };
    this.props.onSubmit(vars);
    console.log(vars);
  }

  _onKeyDown(event: SyntheticKeyboardEvent<*>) {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this._onSubmit();
    }
  }

  render() {
    return (
      <div className={css.composer}>
        <div className={css.header}>
          <div className={css.nickname}>
            {this.props.userName}
            {this.props.isAdmin ? " [ADMIN]" : null}
          </div>
        </div>

        <div className={css.input}>
          <textarea
            placeholder="message"
            className={css.message}
            // type="textarea"
            rows={4}

            maxLength={180}
            value={this.props.sendmessage}
            onChange={(e) => this.props.setMessage(e.target.value)}
            onKeyDown={(e) => this._onKeyDown(e)}
            autoFocus={false}
          />
          <div className={css.button} onClick={(e) => this._onSubmit(e)}>
            SEND&nbsp;<ion-icon name="arrow-forward-circle-sharp"></ion-icon>
          </div>
        </div>
      </div>
    );
  }
}

export default MessageComposer;
