//@flow
import { SET_HIDE_CHAT, SET_USERNAME, SET_EDITING, SET_MESSAGE, ADD_MENTION, SET_SCROLLMODE, SET_USERINTERRUPT, SET_CONNECTED, SET_ADMIN } from "./actiontypes";

import hash from "password-hash";

function adminOK(password: ?string): boolean {
  // const hashed = password && hash.generate(password)
  // console.log(password,hashed)
  //sharon
  if (hash.verify(password, "sha1$3977d6b6$1$5388e81ac8739d1f0a0dc145edb0016e82712c29")) {
    //use this to check for saved admin state
    password && localStorage.setItem("@adminpass", password);
    // console.log("Admin OK")
    return true;
  }
  // console.log("Admin Fail")
  return false;
}

export default function chatState(
  state: Object = {
    hideChat: false,
    userName: localStorage.getItem("@nickname"),
    editing: false,
    sendmessage: "",
    scrollMode: Number(localStorage.getItem("@scrollmode")) || 2,
    userInterrupt: false,
    nicknameLastUpdated: Number(localStorage.getItem("@nicknameupdated")) || 0,
    chatConnected: false,
    isAdmin: adminOK(localStorage.getItem("@adminpass")),
  },
  action: Object
) {
  switch (action.type) {
    case SET_HIDE_CHAT:
      return { ...state, hideChat: action.payload.hide };
    case SET_USERNAME:
      localStorage.setItem("@nickname", action.payload.name);
      return { ...state, userName: action.payload.name };
    case SET_EDITING:
      return { ...state, editing: action.payload.v };
    case SET_MESSAGE:
      return { ...state, sendmessage: action.payload.m };
    case SET_SCROLLMODE:
      localStorage.setItem("@scrollmode", action.payload.v);
      return { ...state, scrollMode: action.payload.v };
    case ADD_MENTION:
      return { ...state, sendmessage: state.sendmessage ? `${state.sendmessage} @${action.payload.m} ` : `@${action.payload.m} ` };
    case SET_USERINTERRUPT:
      return { ...state, userInterrupt: action.payload.v };
    case SET_CONNECTED:
      return { ...state, chatConnected: action.payload.v };
    case SET_ADMIN:
      return { ...state, isAdmin: adminOK(action.payload.v) };
    default:
      return state;
  }
}
