//@flow
import {SET_HIDE_CHAT,SET_USERNAME,SET_EDITING,SET_MESSAGE,ADD_MENTION,SET_SCROLLMODE,SET_USERINTERRUPT,SET_CONNECTED,SET_ADMIN} from './actiontypes';

export const setHideChat = (hide:bool) => ({
  type: SET_HIDE_CHAT,
  payload: {
    hide
  }
});

export const setUsername = (name:string) => ({
  type: SET_USERNAME,
  payload: {
    name
  }
});


export const setEditing = (v:bool) => ({
  type: SET_EDITING,
  payload: {
    v
  }
});

export const setScrollMode = (v:number) => ({
  type: SET_SCROLLMODE,
  payload: {
    v
  }
});

export const setMessage = (m:string) => ({
  type: SET_MESSAGE,
  payload: {
    m
  }
});

export const addMention = (m:string) => ({
  type: ADD_MENTION,
  payload: {
    m
  }
});

export const setUserInterrupt = (v:bool) => ({
  type: SET_USERINTERRUPT,
  payload: {
    v
  }
});

export const setChatConnected = (v:bool) => ({
  type: SET_CONNECTED,
  payload: {
    v
  }
});

export const setAdmin = (v:string) => ({
  type: SET_ADMIN,
  payload: {
    v
  }
});