//@flow
import React, { useState } from "react";
import css from "./message.module.scss";
import type { Message } from "./index";
import moment from "moment";

type Props = {
  message: Message,
  isBot: boolean,
  isMe: boolean,
  isAdmin: boolean,
  addMention: Function,
  username: string,
};
const graphicsServer = "https://vidx-graphics.service.indemand.digital";

const chatroomFilter = "https://chatroom-messagefilter.service.indemand.digital";

function sendMessageToScreen(m: Message) {
  const y = window.confirm(`Are you sure you want to display this message on the tv channel? This cannot be undone.\n${m.Nickname}\n${m.Body}`);
  if (y) {
    // console.log("blocking user: ", m.ClientDeviceID);
    // m.Timestamp = m.Timestamp.toString();
    fetch(graphicsServer + "/postevent", {
      method: "post",
      body: JSON.stringify({
        EventType: "QUEUE_USER_MESSAGE",
        ExtraData: {
          Username: m.Nickname,
          Message: m.Body,
        },
      }),
    }).then(function (response) {
      console.log(response.status);
    });
  }
}

function blockUser(m: Message) {
  const y = window.confirm(`Are you sure you want to PERMANENTLY block the user "${m.Nickname}" who just posted this message? This cannot be undone.\nMessage: \n` + m.Body);
  if (y) {
    console.log("blocking user: ", m.ClientDeviceID);
    m.Timestamp = m.Timestamp.toString();
    fetch(chatroomFilter + "/blockuser", {
      method: "post",
      body: JSON.stringify(m),
    }).then(function (response) {
      console.log(response.status);
    });
  }
}

export default function MessageRow(props: Props) {
  const [opacity, setOpacity] = useState(0);
  const [showMenu, setShowMenu] = useState(false);

  setTimeout(() => setOpacity(1), 100);

  const { message, isMe, isBot, addMention, isAdmin, username } = props;

  const messageIsAdmin = message.ClientDeviceID.includes("_admin");
  const mentionsMe = message.Body.includes("@" + username);

  const cl = isBot ? css.messageFromBot : messageIsAdmin ? css.messageFromAdmin : isMe ? css.messageFromMe : mentionsMe ? css.mentionsMe : null;

  return (
    <div className={[css.message, cl].join(" ")} style={{ opacity: opacity }} onClick={() => setShowMenu(!showMenu)}>
      <div className={[css.menu, showMenu && css.showMenu].join(" ")}>
        <div onClick={() => addMention(message.Nickname)}>@Mention</div>
        {isAdmin && (
          <React.Fragment>
            <div onClick={() => blockUser(message)}>
              <i className="fa fa-times-circle"></i>&nbsp;Block
            </div>
            <div className={css.blockButton} onClick={() => sendMessageToScreen(message)}>
            <i className="fa fa-tv"></i>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={css.header}>
        <div className={css.nickname}>
          {message.Nickname}
          {messageIsAdmin && " [ADMIN]"}
        </div>
        <div className={css.time}>{moment.unix(message.Timestamp).format("HH:mm")}</div>
      </div>
      <div className={css.body}>{message.Body}</div>
    </div>
  );
}
