//@flow

import React from "react";
import css from "./packages.module.scss";
import { gql, useQuery } from "@apollo/client";
import { useParams, useHistory, Link } from "react-router-dom";
// import SVPPlayer from "../components/svpplayer";
// import ShakaPlayer from "../components/shakaplayer";
// import Thumbnail from "./../components/thumbnail";

import Loading from "../components/loading";
import { setPageMeta } from "../helpers";
import Error from "../components/error";
// import Thumb from "../components/chatroom";
import License from "../components/license";

const PACKAGES = gql`
  query GetPackagesData(
    $domain: String!
    $videoid: ID! # ,$embedType: String!
  ) {
    video(id: $videoid) {
      id
      title
      userEdge {
        hasAccess
      }
      images {
        image {
          id
          x100
        }
      }
      paywallEdges {
        id
        paywall {
          id
          title
          description

          ticketTypes {
            id
            title
            value
            recurInterval
          }

          videos {
            id
            video {
              id
              title
              images {
                image {
                  id
                  x100
                }
              }
              userEdge {
                hasAccess
              }
            }
          }
        }
      }
    }

    channels(domain: $domain) {
      id
      logo
      path
      domain
      title
      licenseStatus
      # headerVideo #temp
    }
  }
`;

type Props = { domain: string };
export default function Packages(props: Props) {
  const { videoid } = useParams();
  const history = useHistory();
  const { domain } = props;
  const { loading, error, data } = useQuery(PACKAGES, {
    variables: { domain: domain, videoid: videoid },
  });

  if (loading) return <Loading />;
  if (error) {
    console.error(error);
    return <Error />;
  }
  if (data.channels.length === 0) {
    console.error(error);
    return <Error text="That channel doesn't exist" />;
  }
  if (!data.video) {
    console.error(error);
    return <Error text="That video doesn't exist" />;
  }
  // console.log(data)
  const { video } = data;
  const channel = data.channels[0];
  // const isLiveStream = video.videoSource === "live";
  setPageMeta(channel, video.title);

  const image =
    (video.images.length > 0 && video.images[0].image.x1200) ||
    "https://images.indemand.tv/aHR0cHM6Ly8yMDE3NTgtNjI0MDI5LXJhaWtmY3F1YXhxbmNvZnFmbS5zdGFja3BhdGhkbnMuY29tL3dwLWNvbnRlbnQvdXBsb2Fkcy8yMDE3LzAzL2ltZy1wbGFjZWhvbGRlci0zMDB4MTg5LnBuZw==";

  return (
    <div className={css.wrapper}>
      <div className={css.backdropContainer}>
        <div className={css.backdrop} style={{ backgroundImage: `url(${image})` }}></div>
      </div>

      <div className={css.header}>
        <div className={css.back} onClick={() => history.goBack()}>
          <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
        </div>
        <a href={`/`} className={css.logo}>
            <img src={channel.logo} alt="logo" />
          </a>
      </div>

      <h2>{channel.title}</h2>

      <div className={css.titleWrapper}>
        <div className={css.title}>{video.title}</div>
        <div className={css.titleTag}>is available in the following packages</div>
      </div>

      <div className={css.paywalls}>
        {video.paywallEdges.map((edge) => (
          <Paywall key={edge.id} edge={edge} currentVideo={video} />
        ))}
      </div>
      <License channel={channel} />
    </div>
  );
}

function Paywall(props: { edge: Object, currentVideo: Object }) {
  const maxThumbs = 6;

  const value = props.edge.paywall.ticketTypes
    .map((ticketType) => {
      let s = `£${ticketType.value / 100}`;
      if (ticketType.recurInterval) {
        s += `/${ticketType.recurInterval}`;
      } else {
        s += " one-off payment";
      }
      return s;
    })
    .join(" | ");
  const cost = `${value}`;

  return (
    <div className={css.paywall}>
      <div className={css.paywallheader}>
        <div className={css.title}>{props.edge.paywall.title}</div>
        <BuyNowButton paywall={props.edge.paywall} />
      </div>
      <div className={css.paywalldescription}>{props.edge.paywall.description}</div>
      <div className={css.paywallcost}>{cost}</div>

      <h6>included content</h6>
      <div className={css.thumbnails}>
        <ThumbnailImage video={props.currentVideo} />
        {props.edge.paywall.videos.slice(0, maxThumbs - 1).map((edge) => {
          if (edge.video.id === props.currentVideo.id) return null;
          return <ThumbnailImage key={edge.id} video={edge.video} />;
          // return <Thumbnail key={edge.id}  video={edge.video} />
        })}
        {props.edge.paywall.videos.length > maxThumbs ? <div className={css.more}>+ {props.edge.paywall.videos.length - maxThumbs} more</div> : null}
      </div>
    </div>
  );
}

function ThumbnailImage(props: { video: Object }) {
  const hasAccess = props.video.userEdge && props.video.userEdge.hasAccess === true;

  const c = (
    <div className={css.thumbnail}>
      <img src={props.video.images[0].image.x100} alt={props.video.title} />
      <div className={css.title}>{props.video.title}</div>
      {hasAccess ? <div className={css.hasaccess}>You already have access to this video</div> : null}{" "}
    </div>
  );
  return hasAccess ? <a href={`/video/${props.video.id}`}>{c}</a>:c;
}

function BuyNowButton(props: { paywall: Object }) {
  return (
    <Link to={`/purchase/${props.paywall.id}`}>
      <div className={css.buyNowButton}>
        <div>Buy Now</div>
      </div>
    </Link>
  );
}
