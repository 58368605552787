//@flow

import React, { useEffect } from "react";

export default function Player(props: { id: string }) {
  const { id } = props;
  useEffect(() => {
    var vars = {
      clip_id: id,
      transparent: "true",
      pause: "0",
      repeat: "",
      bg_color: "#ffffff",
      fs_mode: "2",
      no_controls: "",
      start_img: "0",
      start_volume: "100",
      close_button: "",
      brand_new_window: "1",
      auto_hide: "1",
      stretch_video: "",
      player_align: "NONE",
      offset_x: "0",
      offset_y: "0",
      player_color_ratio: 0.6,
      skinAlpha: "50",
      colorBase: "#250864",
      colorIcon: "#ffffff",
      colorHighlight: "#7f54f8",
      direct: "false",
      is_responsive: "true",
      viewers_limit: 0,
      cc_position: "bottom",
      cc_positionOffset: 70,
      cc_multiplier: 0.03,
      cc_textColor: "#ffffff",
      cc_textOutlineColor: "#ffffff",
      cc_bkgColor: "#000000",
      cc_bkgAlpha: 0.1,
      aspect_ratio: "16:9",
      play_button: "1",
      play_button_style: "pulsing",
      sleek_player: "1",
    };
    var svp_player = new window.SVPDynamicPlayer("svp_player" + id, "", "", "", { use_div: "svp_player" + id, skin: "3" }, vars);
    svp_player.execute();
  });

  return <div id={"svp_player" + id}
  style={{width:"auto",boxShadow:"unset!important"}}></div>;
}
