//@flow

import React, { useEffect } from "react";
import css from "./genericinfo.module.scss";
import { useHistory, useLocation } from "react-router-dom";
// import Logo from "../components/logo";
import { gql, useQuery } from "@apollo/client";
import Loading from "../components/loading";
import Error from "../components/error";
import Logo from "./../images/idtv-logo/in-demand-tv-logo-2.png";
import { setPageMeta } from "../helpers";

const SUPPORT = gql`
  query GetChannelForSupport($domain: String!) {
    channels(domain: $domain) {
      id
      logo
      title
      licenseStatus
    }
  }
`;

function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";
  const body = document.body;
  body && body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  body && body.removeChild(textArea);
}
function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function () {
      console.log("Async: Copying to clipboard was successful!");
    },
    function (err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

function Section(props: Object) {
  const id = encodeURI(props.title.replace(/(\s|\?)/g, ""));
  return (
    <section>
      <h2 id={id}>
        {props.title}&nbsp;
        <span
          title="Copy a link to this to your clipboard"
          className={css.copylink}
          onClick={() => {
            // console.log(window.location)
            copyTextToClipboard(window.location.origin + "/support#" + id);
          }}
        >
          <i className="fa fa-copy"></i>
        </span>
      </h2>
      <div className={css.grid}>{props.children}</div>
    </section>
  );
}
function Question(props: Object) {
  const id = encodeURI(props.title.replace(/(\s|\?)/g, ""));
  // console.log(typeof props.children)
  return (
    <div className={css.question}>
      <h3 id={id}>
        {props.title}&nbsp;
        <span
          title="Copy a link to this to your clipboard"
          className={css.copylink}
          onClick={() => {
            copyTextToClipboard(window.location.origin + "/support#" + id);
          }}
        >
          <i className="fa fa-copy"></i>
        </span>
      </h3>

      {typeof props.children !== "string" ? props.children : <p>{props.children}</p>}
    </div>
  );
}

export default function Support(props: { domain: string }) {
  const history = useHistory();
  const { pathname, hash } = useLocation();
  const { domain } = props;
  useEffect(() => {
    // if not a hash link scroll to top
    if (hash === "") {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 500);
    }
  }, [pathname, hash]); // do this on route change

  const { loading, error, data } = useQuery(SUPPORT, {
    variables: { domain: domain },
  });
  if (loading) return <Loading />;
  if (error || data.channels.length === 0) {
    console.error(error);
    return <Error />;
  }
  const channel = data.channels[0];
  setPageMeta(channel, "Support");

  return (
    <div className={css.wrapper}>
      <div className={css.back} onClick={history.goBack}>
        <i className="fa fa-chevron-circle-left"></i>&nbsp;BACK
      </div>
      <img className={css.logo} src={channel.logo || Logo} alt={channel.title} />
      <h1>Help & Support</h1>
      <Section title="Account & Sign-in">
        <Question title="Why do I need to sign in?">
          Signing in is free and makes sure we can keep track of which channels you're interested in and only show you which channels you've asked to see. The only data we store is your email address
          (for your login); passwords are hashed (scrambled) before they are stored.
        </Question>
        <Question title="Are my payment details safe?">
          If you choose to make a payment to watch a pay-per-view video or take out a subscription, the payment transaction is handled by a company called <a href="https://stripe.com">Stripe</a>. We
          don't store any of your payment details - in fact we don't even see them at all.
        </Question>
        <Question title="How can I reset my password?">
          If you're still signed in, sign out using the link near the bottom of the page. Then click to sign in again and choose "forgot password". This will ask you for your email address and we will
          send you reset instructions to that address.
        </Question>
        <Question title="Can I change my email?">
          Please <a href="#Contact-Us">contact support</a> if you wish to change your email address. Please note we would need proof that the existing email address is yours.
        </Question>
      </Section>
      <Section title="Watching videos">
        <Question title="What devices can I watch on?">
          {channel.title} is currently only available using an internet browser such as Chrome, Safari, Firefox or Edge. Most devices with internet access should be able to watch using one of these
          browsers. See the <a href="#Technical-requirements">technical requirements</a> below.
        </Question>
        <Question title="Can I watch on my TV?">
          If your TV has internet access you may be able to watch directly on your TV using its internet browser (see <a href="#Technical-requirements">technical requirements</a>). If this is not
          possible you may be able to link your computer or mobile device to your TV and use the internet browser on there, for example using Apple TV airplay, or Google Chromecast.
          <br />
          
          
          <br />
          {channel.title} is not currently available via Sky or other set-top boxes without using an internet browser or streaming dongle.
        </Question>
        <Question title="Can I watch using an Amazon Fire Stick?">
          It is possible to watch using an Amazon Fire Stick via the built-in web browser. On your fire stick, search for "Silk Browser" or just "Internet" and install the app. Then navigate to https://remfest.live (you can probably keep it short and just type in "remfest.live")<br/><br/>
        </Question>
        <Question title="Technical requirements">
          <ul>
            <li>{channel.title} Channels require an internet browser to run.</li>
            <li>We've tested Google Chrome, Safari, Firefox and Edge. Other modern browsers should work OK. Browsers built in to smart TVs may work but we cannot guarantee this.</li>
            <li>Make sure you're using the latest version of your browser</li>
            <li>Javascript is required - make sure you don't have any security plugins blocking javascript on this site</li>
          </ul>
        </Question>
      </Section>

      <Section title="Pay-per-view">
        <Question title="How does it work?">
          Some of our videos are free to watch if you have signed in with a free account. Some videos and streams require a pay-per-view ticket or subscription. You can purchase a ticket directly in
          the video player. Payments are taken by card using <a href="https://stripe.com">Stripe</a>. IDTV do not store or process your card details at all. We do not even get to see them. If your
          payment is successful, you will receive an email with a ticket code. Keep this code safe - you will need it if you ever want access to the video content and your browser has logged you out.
        </Question>
        <Question title="How many people can use a Pay-Per-View Ticket?">
          Only one person at a time can watch a video with a single pay-per-view ticket. If you share your ticket code with other people, you will be logged out when they use it. If you want to use
          your ticket on a different device to the one it was purchased on, simply enter the ticket code from your confirmation email. Depending on the security settings, you may receive another email
          to confirm the sign-in on a different device. This is normal.
        </Question>
        <Question title="Can I buy a pay-per-view ticket in before the event?">
          Yes. A pay-per-view ticket purchase will remain valid for the event regardless of when it was purchased. Please make sure you keep the ticket password safe as you may need it to re-authorise
          your purchase. You will receive your ticket password by email.
        </Question>
        <Question title="Can I buy a pay-per-view ticket after the event has started?">
          Yes. You can purchase pay-per-view access at any time. Please note that no discount will be applied to tickets purchased after the event has started.
        </Question>

        <Question title="Can I watch a pay-per-view stream on someone else's device?">
          Yes. Pay-per-view purchases are protected from sharing by tracking your IP address, so if you want to watch on a new device or at a new location you will have to authorise the new device.
          <br />
          To authorise a new device, head to the video you want to watch and enter the ticket password that was sent to you in the pay-per-view confirmation email. You will then be emailed a new
          2-factor code to authorise the new device.
          <br />
          Authorising a new device will automatically de-authorise any previous devices.
        </Question>

        <Question title="I've lost my pay-per-view ticket code">
          Your ticket code / password was emailed to you when you made your pay-per-view purchase. Please check your junk mail carefully if you can't find it. As a last resort you can{" "}
          <a href="#Contact-Us">contact our support team</a>.
        </Question>
        <Question title="I've paid but I'm being asked for a 2-factor code?">
          Sometimes, if you want to watch using a different device or network to the one you originally signed up on, we may need to ask you to authorise your new device. This is to help stop fraud
          and password sharing online.
          <br />
          You might also see this if you have been using 4G or different WIFI networks.
          <br />
          <br />
          It's really easy to authorise a new device:
          <ol type="1">
            <li>Enter your ticket password from your payment confirmation email</li>
            <li>That might be all you need! But if we have to authorise your device...</li>
            <li>
              Don't navigate your browser away from the page with the 2-factor warning. It's OK to put your browser into the background on your phone, just don't click anything on the page (like the
              back button).
            </li>
            <li>Check your email for the 2-factor code. It might take a minute to arrive.</li>
            <li>Copy the code from the email, head back to your browser and paste it in the box.</li>
            <li>You should be up and running!</li>
          </ol>
          Please note that you might have to do this for pay-per-view videos any time you change devices. You're only allowed to watch a pay-per-view event on one device at a time.
        </Question>
        <Question title="Can I get a refund?">
          Refunds for pay-per-view events are subject to our refund and cancellation policy as described in full <a href="/terms">here</a>.
          <ul>
            <li>
              You may request a cancellation of a pay-per-view ticket up to one hour before the start of the event by <a href="#Contact-Us">contacting our support team</a>
            </li>
            <li>Cancellations may not be processed until after the event.</li>
            <li>Pay-per-view refunds shall not be granted if you have watched any portion of the event - no matter how short</li>
            <li>
              All purchases are subject to the full <a href="/terms">terms and conditions</a>
            </li>
          </ul>
        </Question>
      </Section>
      <Section title="Contact Us">
        <Question title="Live Chat">
          Live chat is available using the green chat icon at the bottom left of your screen. If our team is online then you should get a reply soon. If we are not currently online you can leave your
          email address and we will reply within 48 hours.
        </Question>
        <Question title="Email support">
          You can contact us on <a href="mailto:support@indemand.tv">support@indemand.tv</a> & we will respond within 48 hours
          <br />
          <br />
          We will endeavour to get back to you as soon as possible. Please note that during busy periods this make take more time.
        </Question>
      </Section>
    </div>
  );
}
