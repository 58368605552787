//@flow

import React from "react";
import css from "./loading.module.scss";

export default function Error(props: { text?: string }) {
  const m = props.text || "Whoops - Something went wrong!";
  return (
    <div className={css.wrapper}>
      <div className={css.idtv}>ERROR</div>
      <div className={css.loading}>{m}</div>
      <div className={css.loading}>
        <u>
          <a
            href="/"
            onClick={(e) => {
              e.preventDefault();
              window.location.reload(true);
            }}
          >
            Try refreshing this page
          </a>
        </u>
        , or quitting and restarting your browser
      </div>
    </div>
  );
}
