//@flow
import React from "react";
import css from "./playlist-single-item.module.scss";
// import Thumbnail from "./thumbnail";
import Loading from "./loading";
import Error from "./error";
import { gql, useQuery } from "@apollo/client";
// import { getAPIURL } from "../helpers";
import { Link } from "react-router-dom";

const PLAYLIST = gql`
  query GetPlaylist($id: Int!) {
    playlist(id: $id) {
      id
      title
      description
      videos {
        id
        title
        # imageThumb
        imageThumb

        posterImageEdge {
          image {
            id
            x1200
          }
        }
        duration
        videoSource
        streamName
        longDescription
        paywallEdges {
          paywall {
            title
            tickets {
              id
              type {
                title
                value
              }
            }
          }
        }
        userEdge {
          id
          viewedPercent
          hasAccess
        }
      }
    }
  }
`;

export default function PlaylistWrapper(props: { playlistID: number }) {
  // const location = useLocation();

  const { loading, error, data } = useQuery(PLAYLIST, {
    variables: { id: props.playlistID },
  });
  if (loading) return <Loading text="Loading videos..." />;
  if (error) {
    console.error(error);
    return <Error />;
  }

  const playlist = data.playlist;

  return <Playlist playlist={playlist} />;
}

type Props = {
  playlist: Object,
};

function Playlist(props: Props) {
  const { playlist } = props;
  if (!playlist) return null;

  const video = playlist.videos[0];
  const hasAccess = !video.userEdge || video.userEdge.hasAccess === true;

  const { posterImageEdge } = video;

  let thumbSrc = "https://201758-624029-raikfcquaxqncofqfm.stackpathdns.com/wp-content/uploads/2017/03/img-placeholder-300x189.png";
  if (posterImageEdge) { thumbSrc = posterImageEdge.image.x1200; }
  else if (video.imageThumb) thumbSrc = video.imageThumb;
  const thumb = <img src={thumbSrc} className={[css.image, hasAccess ? null : css.unavailableImg].join(" ")} alt={video.title}></img>;




  // const hasthumb = video && video.imageThumb;

  // const thumb = hasthumb ? <div className={css.image} style={{ backgroundImage: `url(${getAPIURL() + video.imageThumb})` }}></div> : <div className={css.image} style={{ backgroundImage: `` }}></div>;

  // console.log("PL SINGLE")
  return (
    <Link to={hasAccess ? `/video/${video.id}` : "#"}>
      <div className={[css.wrapper,hasAccess ? null : css.unavailableWrapper].join(" ")}>
        <div className={css.title}>
          <i className="fa fa-stream"></i> {playlist.title}
        </div>
        <div className={css.feature}>
          <div className={css.thumb}>{thumb}</div>
          {hasAccess !== true ? <div className={css.subscribeTag}>Subscribe</div> : null}

          <div className={css.info}>
            <div className={css.title}>
              <i className="fa fa-play"></i>
              <div>{video.title}</div>
            </div>

            <div className={css.desc} dangerouslySetInnerHTML={{ __html: video.longDescription }}></div>
          </div>
        </div>
      </div>
    </Link>
  );
}
