//@flow

import React from "react";
import css from "./footer.module.scss";
import moment from "moment";
import { useLocation, useHistory } from "react-router-dom";
import { doSignOut } from "./../auth/redux/actions";
import { connect } from "react-redux";
import Logo from "./logo";
// import { getChannel } from "../helpers";

function Footer(props: { tokenExists: Boolean, signedIn: Boolean, doSignOut: Function, token: string }) {
  const location = useLocation();
  const history = useHistory();
  if (location.pathname.startsWith("/chat")) return null;
  const { signedIn, token } = props;
  return (
    <React.Fragment>
      <div className={css.wrapper}>
        <div>
          {signedIn ? (
            <a href="/">
              <i className="fa fa-chevron-right"></i>Home
            </a>
          ) : null}
          {signedIn ? (
            <a href="/account">
              <i className="fa fa-chevron-right"></i>My Account
            </a>
          ) : null}
          <a href="/support">
            <i className="fa fa-chevron-right"></i>Help & Support
          </a>
          <a href="/terms">
            <i className="fa fa-chevron-right"></i>Terms of Use
          </a>
          <a href="https://indemand.global/data">
            <i className="fa fa-chevron-right"></i>Privacy Policy
          </a>
          {/* {signedIn ? (
            <a href="/channels">
              <i className="fa fa-chevron-right"></i>Change Channel
            </a>
          ) : null} */}
          {signedIn ? (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                props.doSignOut(token);
                history.push("/");
              }}
            >
              <i className="fa fa-chevron-right"></i>Sign Out
            </a>
          ) : null}
          {/* {!signedIn && tokenExists() ? (
            <a href="/channels">
              <i className="fa fa-chevron-right"></i>Watch Now
            </a>
          ) : null} */}
          {/* {!signedIn && !tokenExists() ? (
            <a href="/channels">
              <i className="fa fa-chevron-right"></i>Sign In
            </a>
          ) : null} */}
        </div>
        <div>
          <div>Get your own channel!</div>

          <div>Contact us now at</div>
          <a href="mailto:support@indemand.tv">support@indemand.tv</a>
        </div>
        <div>
          <p>Copyright In Demand Global Ltd &copy;{moment().format("YYYY")} All rights reserved.</p>
          <p>
            40 Mill Lane, Wallasey, UK. CH44 5UG
            <br />
            +44(0)151 639 8017
          </p>
          <a href="https://indemand.global">More at InDemand.Global</a>
        </div>
      </div>

      <div className={css.footerfinal}>
        <div className={css.poweredby}>powered by</div>
        <Logo width={150} />
        <div className={css.copy}>&copy; In Demand Events Ltd {moment().format("YYYY")}</div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state /*, ownProps*/) => {
  const { auth } = state;
  return {
    signedIn: auth.signedIn,
    token: auth.token,
  };
};

const mapDispatchToProps = {
  doSignOut: doSignOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
