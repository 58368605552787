//@flow
import * as ActionTypes from "./actiontypes";
import store from "../../redux/rootreducer";
import { getAuthURL, getClientID } from "../../helpers";

// import {client as apolloclient} from '../../app';
// import {history} from './../../app';

const authserver = getAuthURL();
// const authserver = "http://localhost/";

export const doSignUp = (email: string, domain:string,password: string,optIn:string) => {
  //network call
  fetch(authserver + "signup", {
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    method: "POST",
    body: JSON.stringify({ email, password ,domain,optIn:optIn === "on" ? "1" : "0"}),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveSignup(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });

  return {
    type: ActionTypes.DO_SIGN_UP,
    payload: {
      email,
      password,
    },
  };
};

export const doSignIn = (email: String, domain: string, password: string) => {
  //network call
  const clientID = getClientID();

  fetch(authserver + "signin", {
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    method: "POST",
    body: JSON.stringify({ email, domain, password, deviceID: clientID }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveSignin(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_SIGN_IN,
    payload: {
      email,
      password,
    },
  };
};

export const forceSignOut = () => {
  console.log("force signout")
  const token = localStorage.getItem("@token") || "force-signout-token-not-found";
  doSignOut(token)
  localStorage.removeItem("@token")
  window.location.reload(true)
}

export const doSignOut = (token:string) => {
  //network call
  fetch(authserver + "signout", {
    // headers: {
    //   Accept: "application/json",
    //   "Content-Type": "application/json",
    // },
    method: "POST",
    body: JSON.stringify({ token }),
  }).then((response) => {
    if (response.status === 200) {
      // response.json().then((j) => {
        // console.log(j);
        // store.dispatch(receiveSignout(j));
      // });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_SIGN_OUT,
    payload: {},
  };
  // );
};
export const doValidateToken = (token: string) => {
  //network call
  // console.log("validate", token);
  fetch(authserver + "validatetoken", {
    headers: {
      Accept: "application/json",
      // "Content-Type": "application/json",
      Auth: token,
    },
    method: "POST",
    // body: JSON.stringify({ email, password }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        // console.log(j);
        store.dispatch(receiveValidateToken(j,token));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_VALIDATE_TOKEN,
    payload: {
      token,
    },
  };
};

export const doForgotPassword = (email: string, domain: string) => {
  //network call
  // console.log("validate",token)
  fetch(authserver + "forgotpassword", {
    method: "POST",
    body: JSON.stringify({ email, domain  }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveForgotPassword(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_FORGOT_PASSWORD,
    payload: {
      email,
    },
  };
};

export const receiveForgotPassword = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_FORGOT_PASSWORD,
    payload: data,
  };
};
export const receiveSignup = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_SIGN_UP,
    payload: data,
  };
};
export const receiveSignin = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_SIGN_IN,
    payload: data,
  };
};
export const receiveValidateToken = (data: Object,token:string) => {
  return {
    type: ActionTypes.RECEIVE_VALIDATE_TOKEN,
    payload: {Token:token,...data},
  };
};
export const onNetworkError = () => {
  return {
    type: ActionTypes.NETWORK_ERROR,
  };
};

export const setScreen = (v: string) => {
  return {
    type: ActionTypes.SET_SCREEN,
    screen: v,
  };
};

export const setShowSplash = (v: boolean) => {
  return {
    type: ActionTypes.SET_SHOW_SPLASHSCREEN,
    value: v,
  };
};

export const doResetCode = (code: string, email: string, domain: string) => {
  //network call
  // console.log("validate",token)
  fetch(authserver + "validateresetcode", {
    method: "POST",
    body: JSON.stringify({ TOTP:  code, Email:  email, Domain:  domain  }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveResetCode(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_RESET_CODE,
    payload: {
      email,
      code,
    },
  };
};
export const receiveResetCode = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_RESET_CODE,
    payload: data,
  };
};

export const doResetPassword = (newpassword: string, email: string, domain: string, code: string) => {
  //network call
  // console.log("validate",token)
  fetch(authserver + "resetpassword", {
    method: "POST",
    body: JSON.stringify({ Totp: code, email, newpassword,  domain }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveResetPassword(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_RESET_PASSWORD,
    payload: {
      email,
    },
  };
};
export const receiveResetPassword = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_RESET_PASSWORD,
    payload: data,
  };
};


export const doVerifyEmail = (data: string,channel:string) => {
  fetch(authserver + "verifyemail", {
    method: "POST",
    body: JSON.stringify({ DataBase64: data }),
  }).then((response) => {
    if (response.status === 200) {
      response.json().then((j) => {
        console.log(j);
        store.dispatch(receiveVerifyEmail(j));
      });
    } else {
      //network error
      console.error(response);
      onNetworkError();
    }
  });
  return {
    type: ActionTypes.DO_VERIFY_EMAIL,
    payload: {
      data,channel
    },
  };
};
export const receiveVerifyEmail = (data: Object) => {
  return {
    type: ActionTypes.RECEIVE_VERIFY_EMAIL,
    payload: data,
  };
};
