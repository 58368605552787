//@flow
import * as ActionTypes from "./actiontypes";
import {client as apolloclient} from '../../app';

const screens = {
  PREVIEW:"PREVIEW",
  SIGNIN: "SIGNIN",
  SIGNUP: "SIGNUP",
  FORGOTPASS: "FORGOTPASS",
  RESETPASS: "RESETPASS",
  ERROR: "ERROR",
  RESETCODE: "RESETCODE",
  CHECKYOUREMAIL:"CHECKYOUREMAIL",
  EMAILVERIFIED:"EMAILVERIFIED"

};
// STATES
// signedin -> show app
// signedout -> auth flow
////
// SCREENS
//// signup
//// signin
//// forgot password
//// reset password

export default function authState(
  state: Object = {
    showSplashScreen: true,
    signedIn: false,
    token: "",
    loading: false,
    screen: screens.PREVIEW,
    error: "",
    errorCode: 0,
    resetEmail: "",
    totp: ""
  },
  action: Object
) {
  switch (action.type) {
    case ActionTypes.NETWORK_ERROR:
      return { ...state, showSplashScreen: false, loading: false, screen: screens.ERROR, error: "Network error - something went wrong connecting to the server", errorCode: 1 };
    case ActionTypes.SET_SCREEN:
      return { ...state, screen: action.screen, errorCode: 0, error: "" };
    case ActionTypes.SET_SHOW_SPLASHSCREEN:
      return { ...state, showSplashScreen: action.value };
    ///////////////////////
    ///////////////////////
    case ActionTypes.DO_SIGN_IN:
      return { ...state, loading: true };
    case ActionTypes.RECEIVE_SIGN_IN: {
      const e = action.payload.ErrorCode;
      if (e === 0 && action.payload.Token !== "") {
        localStorage.setItem("@token", action.payload.Token);
        return { ...state, showSplashScreen: false, loading: false, token: action.payload.Token, signedIn: true, error: "", errorCode: 0 };
      }
      localStorage.removeItem("@token");
      return { ...state, showSplashScreen: false, loading: false, screen: screens.SIGNIN, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
    /////////////////////////
    ///////////////////////

    case ActionTypes.DO_SIGN_UP:
      return { ...state, loading: true };
    case ActionTypes.RECEIVE_SIGN_UP: {
      const e = action.payload.ErrorCode;
      if (e === 0 && action.payload.Token !== "") {
        // localStorage.setItem("@token", action.payload.Token);
        return { ...state, loading: false, screen: screens.CHECKYOUREMAIL,error: "", errorCode: 0 };
      }
      localStorage.removeItem("@token");
      return { ...state, loading: false, screen: screens.SIGNUP, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
    /////////////////////////
    ///////////////////////
    case ActionTypes.DO_SIGN_OUT:
      apolloclient.resetStore()
      localStorage.removeItem("@token");
      return { ...state, loading: false, signedIn: false, token: "", screen: screens.PREVIEW };
    /////////////////////////
    ///////////////////////
    case ActionTypes.DO_FORGOT_PASSWORD:
      return { ...state, loading: true, resetEmail: action.payload.email };
    case ActionTypes.RECEIVE_FORGOT_PASSWORD: {
      const e = action.payload.ErrorCode;

      if (e === 0) {
        return { ...state, showSplashScreen: false, loading: false, screen: screens.RESETCODE, error: "", errorCode: 0, token: "" };
      }
      return { ...state, showSplashScreen: false, loading: false, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
    /////////////////////////
    ///////////////////////
    case ActionTypes.RECEIVE_VALIDATE_TOKEN: {
      const e = action.payload.ErrorCode;
      // console.log(action)
      if (e === 0) {
        return { ...state, showSplashScreen: false, loading: false, token: action.payload.Token, signedIn: true, error: "", errorCode: 0 };
      }
      localStorage.removeItem("@token");
      return { ...state, showSplashScreen: false, loading: false, screen: screens.SIGNIN, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
    /////////////////////////
    ///////////////////////
    case ActionTypes.DO_RESET_CODE:
      return { ...state, loading: true, totp: action.payload.code };
    case ActionTypes.RECEIVE_RESET_CODE: {
      const e = action.payload.ErrorCode;
      if (e === 0) {
        return { ...state, loading: false, screen: screens.RESETPASS, error: "", errorCode: 0 };
      }
      return { ...state, loading: false, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
    /////////////////////////
    ///////////////////////
    case ActionTypes.DO_RESET_PASSWORD:
      // console.log(action.payload, state.resetEmail, state.totp);
      return { ...state, loading: true };
    case ActionTypes.RECEIVE_RESET_PASSWORD: {
      const e = action.payload.ErrorCode;
      if (e === 0) {
        return { ...state, loading: false, screen: screens.SIGNIN, error: "Your password has been changed successfully", errorCode: 200 };
      }
      return { ...state, loading: false, error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" };
    }
        /////////////////////////
    ///////////////////////
    case ActionTypes.DO_VERIFY_EMAIL:
      // console.log(action.payload, state.resetEmail, state.totp);
      return { ...state, loading: true };
    case ActionTypes.RECEIVE_VERIFY_EMAIL: {
      const e = action.payload.ErrorCode;
 
      if (e === 0) {
        return { ...state,showSplashScreen:false, loading: false, screen: screens.EMAILVERIFIED, error: "", errorCode: 0,token:"",signedIn:false };
      }
      return { ...state, showSplashScreen:false,loading: false, screen: screens.EMAILVERIFIED,error: action.payload.Error, errorCode: action.payload.ErrorCode, token: "" ,signedIn:false};
    }
    default:
      return state;
  }
}
