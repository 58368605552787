//@flow
import React from "react";
import css from "./auth.module.scss";

type Props = {
  channel: Object,
  screen: string,
  setScreen: Function,
  // token: string,
  error: string,
  errorCode: number,
  waitingForChannel: boolean,
};

export default function Preview(props: Props) {
  const { screen, setScreen, channel} = props;

  if (screen !== "PREVIEW") return null;

  if (props.waitingForChannel === false && !channel)
    return (
      <div className={css.preview}>
        <div>
            Error
            <br />
            <br />
          </div>
        <div className={css.title}>Sorry</div>
        <div className={css.description}>This browser is not supported. Please try upgrading the browser or OS.</div>
      </div>
    );

    if(!channel)return null;
  return (
    <div className={css.preview}>
      <div className={css.logo}>
        <img src={channel && channel.logo} alt={channel.title} />
      </div>
      <div className={css.title}>{channel.title}</div>
      <div className={css.description}>{channel.longDescription}</div>
      <div className={css.button} onClick={() => setScreen("SIGNIN")} style={{ cursor: "pointer" }}>
        Watch Now
      </div>
    </div>
  );
}
